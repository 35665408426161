import React from "react";

import ContactUs from "./views/contact_us/ContactUs";
import QuantunaOptionsNewLanding from "./views/landing/QuantunaOptionsNewLanding";
import QuantunaOptionSingleChartLanding from "./views/landing/QuantunaOptionSingleChartLanding";
import QuantunaOptionPricer from "./views/pricer/QuantunaPricer";
import QuantunaHomePage from "./views/landing/QuantunaHomePage";
import QuantunaOptionPrices from "./views/options/QuantunaOptionPrices";
import QuantunaOptionScreener from "./views/screener/QuantunaOptionScreener";
import QuantunaSingleOptionPrices from "./views/pricer_single/QuantunaSingleOptionPrices";

export const AppRoutes = [
  { path: "/", element: <QuantunaHomePage /> },
  { path: "/analytics", element: <QuantunaOptionsNewLanding /> },
  { path: "/options", element: <QuantunaOptionPrices /> },
  { path: "/screener", element: <QuantunaOptionScreener /> },
  { path: "/pricer_bulk", element: <QuantunaOptionPricer /> },
  { path: "/pricer", element: <QuantunaSingleOptionPrices /> },
  {
    path: "/osinglechartview/:chart_id/:underlying_index/:iv/:strikeOrDelta/:coin/:display_type",
    element: <QuantunaOptionSingleChartLanding />,
  },
  { path: "/contact_us", element: <ContactUs /> },
];

export default AppRoutes;
