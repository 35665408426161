import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useLoadUnderlyingVolumeAggregatedByInstrumentQuery } from "../../../../api/OptionsApi";
import transparent from "../../../../assets/images/quantuna-name-transparent-light.png";
import { QuantunaChartTitle } from "../../../dashboard/components/basic/BasicChartComponents";
import Spinner from "../../../spinner/Spinner";

export const UnderlyingVolumeAggregatedByInstrument_Intern = (props) => {
  // there will always be a base currency in the props, lets get that to build the underlying aggregate open interest chart
  const underlying = props.instrument.base;
  // calling the service side to get the data
  const underlyingAggregateOpenInterestQ =
    useLoadUnderlyingVolumeAggregatedByInstrumentQuery(underlying);
  const data =
    underlyingAggregateOpenInterestQ &&
    underlyingAggregateOpenInterestQ.isSuccess &&
    underlyingAggregateOpenInterestQ.data
      ? underlyingAggregateOpenInterestQ.data
      : undefined;

  console.info(
    "Received useLoadUnderlyingVolumeAggregatedByInstrumentQuery data from the server",
    underlyingAggregateOpenInterestQ
  );

  const getOption = () => {
    return {
      title: {
        text: `${props.instrument.base} (${props.chart_attrs.description_1})`,
      },

      //   subtitle: {
      //     text: `${props.chart_attrs.description_1}`,
      //   },

      credits: {
        enabled: true,
        href: "https://www.quantuna.com",
        text: "www.quantuna.com",
      },

      chart: {
        // width: 1200,
        type: "column",
        events: {
          render: function () {
            const chart = this,
              bgBBox = chart.plotBackground.getBBox();

            if (!chart.bgImage) {
              chart.bgImage = chart.renderer
                .image(transparent, 0, 0, 300, 100)
                .add();
            }

            chart.bgImage.attr({
              x: "calc(50% - 150px)",
              y: "calc(50% - 75px)",
            });
          },
        },
      },

      legend: {
        enabled: true,
      },

      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
          },
        },
      },

      yAxis: [
        {
          title: {
            text: "Volume(USD)",
          },
        },
      ],
      xAxis: {
        name: "Instruments",
        categories: data.categories,
      },

      series: [
        {
          name: data.seriesNames[0],
          data: data.seriesData[0],
        },
        {
          name: data.seriesNames[1],
          data: data.seriesData[1],
        },
      ],
      colors: [
        "#910000",
        "#2f7ed8",
        "#1aadce",
        "#0d233a",
        "#8bbc21",
        "#492970",
        "#f28f43",
        "#77a1e5",
        "#c42525",
        "#a6c96a",
      ],
      //   https://api.highcharts.com/highcharts/series.column.data
    };
  };

  if (!data) {
    return <Spinner />;
  } else {
    return (
      <div>
        {data && (
          <HighchartsReact highcharts={Highcharts} options={getOption()} />
        )}
      </div>
    );
  }
};

export const UnderlyingVolumeAggregatedByInstrument = (props) => {
  console.info("Building UnderlyingVolumeAggregatedByInstrument  chart", props);

  return (
    <>
      <QuantunaChartTitle {...props} />
      <UnderlyingVolumeAggregatedByInstrument_Intern {...props} />
    </>
  );
};
