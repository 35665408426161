import React, { useState } from "react";
import nat1large from "../../../../img/nat-1-large.jpg";
import nat1 from "../../../../img/nat-1.jpg";
import nat2large from "../../../../img/nat-2-large.jpg";
import nat2 from "../../../../img/nat-2.jpg";
import nat3large from "../../../../img/nat-3-large.jpg";
import nat3 from "../../../../img/nat-3.jpg";
import comparative_options from "../../../../img/comparative-options.jpg";
import options_dashboard from "../../../../img/options-dashboard.jpg";
import options_dashboard_sq from "../../../../img/options-dashboard-sq.jpg";
import vol_surface from "../../../../img/vol-surface.jpg";
import { Link } from "react-router-dom";

export const HomePageLanding = (props) => {
  return (
    <main>
      {/* quantuna main header */}
      <header class="header">
        {/* <div class="header__logo-box">
          <img
            class="header__logo-box__logo"
            src="img/logo-white.png"
            alt="logo"
          />
        </div> */}

        <div class="header__text-box">
          <h1 class="header__text-box__heading-primary">
            <span class="header__text-box__heading-primary__heading-primary-main">
              Option Analytics
            </span>
            <span class="header__text-box__heading-primary__heading-primary-sub">
              Built for your investing needs
            </span>
          </h1>

          <Link to="/options" className="btn btn-white btn-animated btn--green">
            Explore&rarr;
          </Link>
          {/* <a href="#" class="btn btn-white btn-animated btn--green">
            Explore&rarr;
          </a> */}
        </div>
      </header>
      {/* main home page content to come here */}
      {/* about quantuna */}
      <section class="section-about">
        <div class="u-center-text u-margin-bottom-large">
          <h2 class="heading-secondary">
            Interactive Option Analytics & Pricing
          </h2>
        </div>
        <div class="row">
          <div class="col-1-of-2">
            {/* text content here */}
            <h3 class="heading-tertiary u-margin-bottom-small">
              Interactive Bitcoin & Ethereum Option Analytics
            </h3>
            <p class="paragraph">
              Graphical representation of volatility numbers pulled in real-time
              from Deribit and other highly active option trading exchanges.
              Visualize implied volatility numbers with 7d, 30d, and 365d
              comparative options.
            </p>

            <h3 class="heading-tertiary u-margin-bottom-small">
              Option Pricing Tool
            </h3>
            <p class="paragraph">
              Pricing engine built to provide the ability to analyze current
              option market price. Use it to build your own option contracts.
            </p>

            <h3 class="heading-tertiary u-margin-bottom-small">Vol Surface</h3>
            <p class="paragraph">
              Generate interactive vol-surface graphs from collected data as
              well as for constructed options.
            </p>

            <Link to="/options" className="btn-text">
              Explore&rarr;
            </Link>
            {/* <a href="#" class="btn-text">
              Explore&rarr;
            </a> */}
          </div>
          <div class="col-1-of-2">
            <div class="composition">
              <img
                srcset={
                  comparative_options +
                  " 300w, " +
                  comparative_options +
                  " 1000w"
                }
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 18.75em"
                alt="photo 1"
                class="composition__photo composition__photo--p1"
                src={comparative_options}
              />

              <img
                srcset={
                  options_dashboard_sq +
                  " 300w, " +
                  options_dashboard_sq +
                  " 1000w"
                }
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 18.75em"
                alt="photo 2"
                class="composition__photo composition__photo--p2"
                src={options_dashboard_sq}
              />

              <img
                srcset={vol_surface + " 300w, " + vol_surface + " 1000w"}
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 18.75em"
                alt="photo 3"
                class="composition__photo composition__photo--p3"
                src={vol_surface}
              />
              <img
                src={comparative_options}
                alt="photo 1"
                class="composition__photo composition__photo--p1"
              />
              <img
                src={options_dashboard_sq}
                alt="photo 2"
                class="composition__photo composition__photo--p2"
              />
              <img
                src={vol_surface}
                alt="photo 3"
                class="composition__photo composition__photo--p3"
              />
            </div>
          </div>
        </div>
      </section>

      {/* a quick sneak peak into quantuna option analytics and option pricing */}
      <section className="about-option-analytics">
        {/* <div>
          to display some option analytics here with pricing calculation
        </div> */}
      </section>

      {/* a quick sneak peak into quantuna team */}
      <section className="about-team">
        {/* <div>to displau information about quantuna team</div> */}
      </section>
    </main>
  );
};
