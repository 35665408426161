import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from '../global';

export const allVolSurfaceApi = createApi({
	reducerPath: 'allVolSurfaceApi',
	baseQuery: fetchBaseQuery({ 
		baseUrl: api.ssa.url+"/api/",
		prepareHeaders: (headers, { getState }) => {
			const token = getState() && getState().auth && getState().auth.token
			// If we have a token set in state, let's assume that we should be passing it.
			if (token) {
			  headers.set('authorization', `Bearer ${token}`)
			}
			// headers.set('content-type', 'application/json')
			return headers
		  }
	}),
	endpoints: (builder) => ({
		loadVolSurface: builder.query({
			query: (currency) => ({
			  url: `loadVolSurface`,
			  method: 'POST',
			  body: {
				  'currency': currency,
				  'content-type': 'application/json'
				}, // This is the same as passing 'text'
			}),
		}),
	}),
});

export const { useLoadVolSurfaceQuery } = allVolSurfaceApi;