import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../global";

export const allDataProductApi = createApi({
  reducerPath: "allDataProductApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.ssa.url + "/api/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState() && getState().auth && getState().auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      // headers.set('content-type', 'application/json')
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loadAllDataProductConfigs: builder.query({
      query: () => ({
        url: `getAllDataProductConfigs`,
        method: "POST",
        body: {
          "content-type": "application/json",
        },
      }),
    }),
  }),
});

export const { useLoadAllDataProductConfigsQuery } = allDataProductApi;
