import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faInfoCircle,
  faCircleInfo,
  faInfo,
  faCircleQuestion,
  faCircle,
  faChartLine,
  faTable,
  faLightbulb,
  faThumbTack,
  faLink,
  faArrowUpRightFromSquare,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { ChartsFactory } from "../../../components/charts/ChartsFactory";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useOriginLocation } from "../../../../global";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import styled from "styled-components";
import { CardTitle } from "reactstrap";
import Markdown from "marked-react";
import styles from "./BasicChartComponents.module.css";
import { Link } from "react-router-dom";

const QuantunaButton73090 = (props) => {
  const Button73090 = styled.button`
    padding-left: 0.4em;
    padding-right: 0.4em;
    font-size: 0.8em;
    background-color: ${(props) =>
      props.daysToExpiry == 7
        ? "#f35050"
        : props.daysToExpiry == 30
        ? "#50f450"
        : props.daysToExpiry == 90
        ? "#64aceb"
        : props.daysToExpiry == 180
        ? "#f4a0e5"
        : "white"};
  `;
  return (
    <>
      <Button73090 daysToExpiry={7} value={7} onClick={props.clickHandler}>
        7
      </Button73090>
      <Button73090 daysToExpiry={30} value={30} onClick={props.clickHandler}>
        30
      </Button73090>
      {/* <Button73090 daysToExpiry={90} value={90} onClick={props.clickHandler}>
        90
      </Button73090>
      <Button73090 daysToExpiry={180} value={180} onClick={props.clickHandler}>
        180
      </Button73090> */}
    </>
  );
};

const QuantunaSelectedContainerPinit = (props) => {
  return (
    <div className={"quantuna-pinned-selected-" + props.pinned_or_not}>
      {props.children}
    </div>
  );
};

const QuantunaToolTip = (props) => {
  return (
    <div className="qtooltip">
      {props.children}
      <span className={"qtooltiptext qtooltiptext--" + props.tooltip_txt_class}>
        {props.tooltip_title}
      </span>
    </div>
  );
};

const QuantunaBuySellHoldIndicator = (props) => {
  return (
    <div className="margin-left-sm">
      {props.buy_sell_hold_signal == "buy" && (
        <QuantunaToolTip tooltip_title="Buy signal" tooltip_txt_class="buy">
          <FontAwesomeIcon icon={faLightbulb} className="quantuna-buy-signal" />
        </QuantunaToolTip>
      )}
      {props.buy_sell_hold_signal == "sell" && (
        <QuantunaToolTip tooltip_title="Sell signal" tooltip_txt_class="sell">
          <FontAwesomeIcon
            icon={faLightbulb}
            className="quantuna-sell-signal"
          />
        </QuantunaToolTip>
      )}
      {props.buy_sell_hold_signal == "hold" && (
        <QuantunaToolTip tooltip_title="Hold signal" tooltip_txt_class="hold">
          <FontAwesomeIcon
            icon={faLightbulb}
            className="quantuna-hold-signal"
          />
        </QuantunaToolTip>
      )}
    </div>
  );
};

const QuantunaChartTitle = (props) => {
  const [linkCopiedState, setLinkCopiedState] = useState("");
  const origin = useOriginLocation();
  const chart_attrs = props.chart_attrs;
  // underlying index works only when there is options data
  const category = chart_attrs.category;
  const instrument = props.instrument;
  const underlying_index = props.instrument.underlyingIndex;

  console.info(
    "Building title for " + chart_attrs.template + " instrument: ",
    instrument,
    " and chart: ",
    chart_attrs
  );

  // either we are passing show-pop-out from the call to chart-title or we have it setup in the db table
  const show_pop_out = chart_attrs.show_pop_out == 1 || props.show_pop_out;

  // check if the 7-30-90 button for option strike chart comparative study is enabled
  const is73090_enabled =
    chart_attrs.is_73090_enabled && chart_attrs.is_73090_enabled == 1;

  const setLinkCopied = () => {
    setLinkCopiedState("copied");
    setTimeout(() => setLinkCopiedState(""), 2000);
  };

  // console.info("props.show_pop_out", props.show_pop_out, !props.show_pop_out);

  // the chart-url will be different based on whether this is spots or options chart
  // use props.category to switch between stock or option link for pop-out
  let chartUrl = "";
  if (category == "spot" || category == "indicators") {
    // chart-url for spot
    chartUrl =
      "/singlechartview/" + chart_attrs.chart_id + "/" + instrument.base;
  } else if (category == "option") {
    // chart for option
    // option charts may have additional arguments, what can be the best way to add those here?
    // one way is => construct the additional parameters and pass it down.
    // iv: selectedIV,
    // strikeOrDelta: props.strikeOrDelta,
    if (instrument.iv && instrument.strikeOrDelta) {
      chartUrl =
        "/osinglechartview/" +
        chart_attrs.chart_id +
        "/" +
        underlying_index +
        "/" +
        instrument.iv +
        "/" +
        instrument.strikeOrDelta +
        "/" +
        instrument.instrument_base +
        "/chart";
    }
  }

  if (show_pop_out) {
    console.info("Will popout to chart url", chartUrl, category);
  }

  return (
    <CardTitle>
      <div className="quantuna-chart-title-wrapper">
        <div className="quantuna-chart-title-wrapper__left">
          {/* put the left aligned buttons etc here */}
          {/* the comparative days options will show up only for option some charts, get it from attribs */}
          {is73090_enabled == true && (
            <QuantunaButton73090 clickHandler={props.click73090Handler} />
          )}
        </div>
        <div className="quantuna-chart-title-wrapper__right">
          <span className="quantuna-title-left-container">
            {/* <span>{chart_attrs.description_1}</span> */}
            <QuantunaBuySellHoldIndicator
              buy_sell_hold_signal={props.buy_sell_hold_signal}
            />
          </span>

          {show_pop_out && (
            <button className="qbutton quantuna-chart-title-btns">
              <Link to={chartUrl} target="_blank">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
            </button>
          )}
          <CopyToClipboard onCopy={setLinkCopied} text={chartUrl}>
            <button className="qbutton quantuna-chart-title-btns">
              <FontAwesomeIcon
                icon={faLink}
                className={
                  linkCopiedState && linkCopiedState == "copied"
                    ? "fading-orange"
                    : ""
                }
              />
            </button>
          </CopyToClipboard>

          <button
            className="qbutton quantuna-chart-title-btns"
            onClick={() => props.set_on_display("chart")}
          >
            <FontAwesomeIcon icon={faChartLine} />
          </button>
          {props.chart_attrs.show_chart_datatable && (
            <button
              className="qbutton quantuna-chart-title-btns"
              onClick={() => props.set_on_display("table")}
            >
              <FontAwesomeIcon icon={faTable} />
            </button>
          )}
          {props.chart_attrs.show_chart_info &&
            props.chart_attrs.chart_info && (
              <button
                className="qbutton quantuna-chart-title-btns"
                onClick={() => props.set_on_display("info")}
              >
                <FontAwesomeIcon icon={faInfo} />
              </button>
            )}
          {false && (
            <button
              className="qbutton quantuna-chart-title-btns"
              onClick={() => props.toggle_pin_the_chart()}
            >
              <FontAwesomeIcon icon={faThumbTack} />
            </button>
          )}
        </div>
      </div>
    </CardTitle>
  );
};

const QuantunaChart = (props) => {
  return (
    <div
      className="chart-wrapper"
      style={{
        width: "100%",
        margin: "0 auto",
        // height: props.card_height,
        overflow: autoBatchEnhancer,
      }}
    >
      <ChartsFactory
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        display_type={props.display_type}
        underlying_index={props.underlying_index}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    </div>
  );
};

const QuantunaChartInfo = (props) => {
  const info = props.chart_attrs.chart_info;
  return (
    <div
      className={styles["quantuna-chart-info"]}
      style={{ overflow: "auto", height: props.card_height }}
      active={props.on_display == "info" ? 1 : 0}
    >
      <Markdown>{info}</Markdown>
    </div>
  );
};

export {
  QuantunaChartTitle,
  QuantunaSelectedContainerPinit,
  QuantunaChartInfo,
  QuantunaChart,
};
