import React, { useState, useEffect, useRef, Suspense } from "react";
import styles from "./CoreComponents.module.css";

const OperationCompletionMessage = (props) => {
  const closeCompletionMessage = (event) => {
    // pass any operation you may want to perform in close_completion_messge
    props.close_completion_messge();
  };

  return (
    <div className={styles["operation-completion-msg-container"]}>
      <div className={styles["completion-message-container"]}>{props.msg}</div>
      <button
        key="completion-button-key"
        className={`${styles["quantuna-msg-completion-btn"]}`}
        onClick={closeCompletionMessage}
      >
        CLOSE
      </button>
    </div>
  );
};

export default OperationCompletionMessage;
