import React, { useState, useEffect } from "react";
import { api } from "../../../global";
import axios from "axios";
import { yyyymmdd_today } from "../../../util/UtilFunctions";

export const ButterflyTable = (props) => {
  return (
    <>
      <div className="butterfly--table">
        {/* adding a title header row */}
        <div className="butterfly--table--row butterfly--table--row--titleheader">
          <div
            className="butterfly--table--row--cell"
            key={"butterfly--table--row--cell longbutterfly"}
          >
            <span className="titleheadercell">
              {props.title1} {props.title2} {props.title3}
            </span>
          </div>
        </div>
        <div className="butterfly--table--row butterfly--table--row--header">
          {/* make each row a collecion of cells */}
          {[
            "Exp Date",
            "underlying_price",
            "Leg1 Strike",
            "Leg1 Bid",
            "Leg1 Ask",
            "Leg2 Strike",
            "Leg2 Bid",
            "Leg2 Ask",
            "Leg3 Strike",
            "Leg3 Bid",
            "Leg3 Ask",
            "Max Loss",
            "Max Profit",
            "Max Profit%",
            "BE+",
            "BE-",
          ].map((a) => (
            <div className="butterfly--table--row--cell">{a}</div>
          ))}
        </div>

        {/* data row comes here */}
        <div className="butterfly--table--row butterfly--table--row--data">
          {/* make each row a collecion of cells */}
          {[
            "Exp Date",
            "underlying_price",
            "Leg1 Strike",
            "Leg1 Bid",
            "Leg1 Ask",
            "Leg2 Strike",
            "Leg2 Bid",
            "Leg2 Ask",
            "Leg3 Strike",
            "Leg3 Bid",
            "Leg3 Ask",
            "Max Loss",
            "Max Profit",
            "Max Profit%",
            "BE+",
            "BE-",
          ].map((a) => (
            <div className="butterfly--table--row--cell">{"--"}</div>
          ))}
        </div>
      </div>
    </>
  );
};

export const ExpandedButterflyTable = (props) => {
  return (
    <>
      <ButterflyTable
        title1={"Long"}
        title2={"Call"}
        title3={"Butterfly"}
        data_key={"LongCall"}
        data={props.allButterfly}
      />
      <ButterflyTable
        title1={"Long"}
        title2={"Put"}
        title3={"Butterfly"}
        data_key={"LongPut"}
        data={props.allButterfly}
      />
      <ButterflyTable
        title1={"Short"}
        title2={"Call"}
        title3={"Butterfly"}
        data_key={"ShortCall"}
        data={props.allButterfly}
      />
      <ButterflyTable
        title1={"Short"}
        title2={"Put"}
        title3={"Butterfly"}
        data_key={"ShortPut"}
        data={props.allButterfly}
      />
    </>
  );
};

export const AllButterflyTable = (props) => {
  console.log("data from the props", props.data_key, props.data);
  const data_key = props.data_key;
  const data = props.data && data_key in props.data && props.data[data_key];
  return (
    <>
      <div className="butterfly--table">
        {/* adding a title header row */}
        <div className="butterfly--table--row butterfly--table--row--titleheader">
          <div
            className="butterfly--table--row--cell"
            key={"butterfly--table--row--cell longbutterfly"}
          >
            <span className="titleheadercell">
              {props.title1} {props.title2} {props.title3}
            </span>
          </div>
        </div>
        <div
          className="butterfly--table--row butterfly--table--row--header"
          key={"butterlfy-header"}
        >
          {/* make each row a collecion of cells */}
          {[
            "SrNo",
            "Exp Date",
            "underlying_price",
            "Leg1 Strike",
            "Leg1 Bid",
            "Leg1 Ask",
            "Leg2 Strike",
            "Leg2 Bid",
            "Leg2 Ask",
            "Leg3 Strike",
            "Leg3 Bid",
            "Leg3 Ask",
            "Max Loss",
            "Max Profit",
            "Max Profit%",
            "BE+",
            "BE-",
            "Call",
            "Long",
          ].map((a) => (
            <div
              className="butterfly--table--row--cell"
              key={"butterfly--table--row--cell-headerkey-" + a}
            >
              {a}
            </div>
          ))}
        </div>

        {/* data row comes here */}
        {data &&
          data.map((recrd, idx) => (
            <div
              className="butterfly--table--row butterfly--table--row--data"
              key={"butterfly--table--row--cell-key-" + idx}
            >
              {/* make each row a collecion of cells */}
              {[
                "SrNo",
                "exp_date",
                "underlying_price",
                "leg1_strike",
                "leg1_bid",
                "leg1_ask",
                "leg2_strike",
                "leg2_bid",
                "leg2_ask",
                "leg3_strike",
                "leg3_bid",
                "leg3_ask",
                "max_loss",
                "max_profit",
                "max_profit_pct",
                "be_plus",
                "be_minus",
                "call_fly",
                "long_fly",
              ].map((dk) => (
                <div
                  className="butterfly--table--row--cell"
                  key={"butterfly--table--row--cell-key-" + idx + dk}
                >
                  {dk === "SrNo" && idx}
                  {dk !== "SrNo" && recrd[dk]}
                </div>
              ))}
            </div>
          ))}

        {!data && (
          <div className="butterfly--table--row butterfly--table--row--data">
            {/* make each row a collecion of cells */}
            {[
              "Exp Date",
              "underlying_price",
              "Leg1 Strike",
              "Leg1 Bid",
              "Leg1 Ask",
              "Leg2 Strike",
              "Leg2 Bid",
              "Leg2 Ask",
              "Leg3 Strike",
              "Leg3 Bid",
              "Leg3 Ask",
              "Max Loss",
              "Max Profit",
              "Max Profit%",
              "BE+",
              "BE-",
            ].map((a) => (
              <div
                className="butterfly--table--row--cell"
                key={"butterfly-nodata-datacell" + a}
              >
                {"--"}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export const Butterfly = (props) => {
  const butterfly_title = props.butterfly_title;

  // all strangle and straddle data array
  const [allButterfly, setAllButterfly] = useState(null);

  // construct the url
  var url = api.ssa.url + "/" + api.ssa.getAllButterflyData;
  const today_str = yyyymmdd_today();
  console.log(
    "Gettig all butterfly screener data from server now - ",
    url,
    props.underlying,
    today_str
  );

  function FetchAllButterflyData() {
    axios
      .post(
        url,
        {
          underlying: props.underlying,
          calculation_date: today_str,
          apikey:
            localStorage.getItem("apikey") == null
              ? "GLOBAL"
              : localStorage.getItem("apikey"),
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        // retrieve data from response
        console.log(
          "Printing the butterfly response from server for coin - " +
            props.underlying,
          response
        );
        return response.data;
      })
      .then((data) => {
        console.log(
          "Setting the data now for coin - " + props.underlying,
          data
        );
        data && setAllButterfly(data);
      })
      .catch((error) => {
        console.error("Error happened while getting the butterfly data", error);
      });
  }

  // useEffect to load the data on component load
  useEffect(() => {
    FetchAllButterflyData();
  }, [props.underlying]);
  return (
    <div className="butterfly-container">
      <div
        className="butterfly--title"
        key={"butterfly-container-title" + butterfly_title}
      >
        {butterfly_title}
      </div>
      <AllButterflyTable
        title1={""}
        title2={"Butterfly"}
        title3={"(" + props.underlying + ")"}
        data_key={"AllData"}
        data={allButterfly}
      />
    </div>
  );
};
