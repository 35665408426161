import React, { useState, useEffect } from "react";
import { api } from "../../../global";
import axios from "axios";
import { yyyymmdd_today } from "../../../util/UtilFunctions";

export const IronCondorTable = (props) => {
  // This is the iron-condor table
  console.log("data from the props", props.data_key, props.data);
  const data_key = props.data_key;
  const data = props.data && data_key in props.data && props.data[data_key];
  return (
    <>
      <div className="ironcondor--table">
        {/* this is the ironcondor table */}
        {/* adding a title header row */}
        <div className="ironcondor--table--row ironcondor--table--row--titleheader">
          <div
            className="ironcondor--table--row--cell"
            key={"ironcondor--table--row--cell longironcondor"}
          >
            <span className="titleheadercell">
              {props.title1} {props.title2}
            </span>
          </div>
        </div>

        {/* starting the table header and data rows here */}
        <div
          className="ironcondor--table--row ironcondor--table--row--header"
          key="ironcondor-header-row"
        >
          {/* make each row a collecion of cells */}
          {[
            "SrNo",
            "Exp Date",
            "underlying_price",
            "Leg1 Strike",
            "Leg1 Bid",
            "Leg1 Ask",
            "Leg2 Strike",
            "Leg2 Bid",
            "Leg2 Ask",
            "Leg3 Strike",
            "Leg3 Bid",
            "Leg3 Ask",
            "Leg4 Strike",
            "Leg4 Bid",
            "Leg4 Ask",
            "Max Loss",
            "Max Profit",
            "Max Profit%",
            "BE+",
            "BE-",
            "Long/Short",
          ].map((a) => (
            <div
              className="ironcondor--table--row--cell"
              key={"iron-condor-cell-" + a}
            >
              {a}
            </div>
          ))}
        </div>

        {/* starting the data rows */}
        {data &&
          data.map((recrd, idx) => (
            <div
              className="ironcondor--table--row ironcondor--table--row--data"
              key={"ironcondor-data-" + idx}
            >
              {/* make each row a collecion of cells */}
              {[
                "SrNo",
                "exp_date",
                "underlying_price",
                "leg1_strike",
                "leg1_bid",
                "leg1_ask",
                "leg2_strike",
                "leg2_bid",
                "leg2_ask",
                "leg3_strike",
                "leg3_bid",
                "leg3_ask",
                "leg4_strike",
                "leg4_bid",
                "leg4_ask",
                "max_loss",
                "max_profit",
                "max_profit_pct",
                "be_plus",
                "be_minus",
                "long_iron",
              ].map((dk) => (
                <div
                  className="ironcondor--table--row--cell"
                  key={"ironcondor--table--row--cell--key" + idx + dk}
                >
                  {dk === "SrNo" && idx}
                  {dk !== "SrNo" && recrd[dk]}
                </div>
              ))}
            </div>
          ))}
      </div>
    </>
  );
};

export const IronCondor = (props) => {
  const ironcondor_title = props.ironcondor_title;

  // all strangle and straddle data array
  const [allIronCondors, setAllIronCondors] = useState(null);

  // construct the url
  var url = api.ssa.url + "/" + api.ssa.getAllIronCondors;
  const today_str = yyyymmdd_today();
  console.log(
    "Gettig all ironcondors screener data from server now - ",
    url,
    props.underlying,
    today_str
  );

  function FetchAllIronCondorsData() {
    axios
      .post(
        url,
        {
          underlying: props.underlying,
          calculation_date: today_str,
          apikey:
            localStorage.getItem("apikey") == null
              ? "GLOBAL"
              : localStorage.getItem("apikey"),
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        // retrieve data from response
        console.log(
          "Printing the ironcondors response from server for coin - " +
            props.underlying,
          response
        );
        return response.data;
      })
      .then((data) => {
        console.log(
          "Setting the ironcondors data now for coin - " + props.underlying,
          data
        );
        data && setAllIronCondors(data);
      })
      .catch((error) => {
        console.error(
          "Error happened while getting the ironcondors data",
          error
        );
      });
  }

  // useEffect to load the data on component load
  useEffect(() => {
    FetchAllIronCondorsData();
  }, [props.underlying]);

  return (
    <div className="ironcondor-container">
      <div
        className="ironcondor--title"
        key={"ironcondor-container-title" + ironcondor_title}
      >
        {ironcondor_title}
      </div>
      <IronCondorTable
        title1={"Iron Condors"}
        title2={"(" + props.underlying + ")"}
        data_key={"AllData"}
        data={allIronCondors}
      />
    </div>
  );
};
