import React, { useState } from "react";

export const NAV_STATE_ACTION_TYPES = {
  UPDATE_NAV_CATEGORY: "nav/update/category",
  UPDATE_SPOT_CURRENCY: "nav/update/spot/currency",
  UPDATE_OPTION_CURRENCY: "nav/update/option/currency",
  UPDATE_OPTION_EXCHANGE_CURRENCY: "nav/update/option/currency_exchange",
  UPDATE_INDICATORS_CURRENCY: "nav/update/indicators/currency",
  ADD_SPOT_CURRENCY: "nav/add/spot/currency",
  ADD_OPTION_CURRENCY: "nav/add/option/currency",
  ADD_INDICATORS_CURRENCY: "nav/add/indicators/currency",
  DEL_SPOT_CURRENCY: "nav/del/spot/currency",
  DEL_OPTION_CURRENCY: "nav/del/option/currency",
  DEL_INDICATORS_CURRENCY: "nav/del/indicators/currency",
};

const INIT_NAV_STATE = {
  category: "indicators",
  optionExchangeCurrency: {},
  spotCurrencies: [],
  optionCurrencies: [],
  indicatorsCurrencies: [],
};

const AppNavStateReducer = (state = INIT_NAV_STATE, action) => {
  switch (action.type) {
    case NAV_STATE_ACTION_TYPES.UPDATE_NAV_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case NAV_STATE_ACTION_TYPES.UPDATE_OPTION_EXCHANGE_CURRENCY:
      return {
        ...state,
        optionExchangeCurrency: {
          currency: action.payload,
          exchange: action.exchangePayload,
        },
      };
    case NAV_STATE_ACTION_TYPES.UPDATE_SPOT_CURRENCY:
      return {
        ...state,
        spotCurrencies: state.spotCurrencies.includes(action.payload)
          ? state.spotCurrencies.filter(
              (currency) => currency != action.payload
            )
          : [action.payload, ...state.spotCurrencies],
      };
    case NAV_STATE_ACTION_TYPES.UPDATE_OPTION_CURRENCY:
      return {
        ...state,
        optionCurrencies: state.optionCurrencies.includes(action.payload)
          ? state.optionCurrencies.filter(
              (currency) => currency != action.payload
            )
          : [action.payload, ...state.optionCurrencies],
      };
    case NAV_STATE_ACTION_TYPES.UPDATE_INDICATORS_CURRENCY:
      return {
        ...state,
        indicatorsCurrencies: state.indicatorsCurrencies.includes(
          action.payload
        )
          ? state.indicatorsCurrencies.filter(
              (currency) => currency != action.payload
            )
          : [action.payload, ...state.indicatorsCurrencies],
      };
    case NAV_STATE_ACTION_TYPES.ADD_SPOT_CURRENCY:
      return {
        ...state,
        spotCurrencies: [action.payload, ...state.spotCurrencies],
      };
    case NAV_STATE_ACTION_TYPES.ADD_OPTION_CURRENCY:
      return {
        ...state,
        optionCurrencies: [action.payload, ...state.optionCurrencies],
      };
    case NAV_STATE_ACTION_TYPES.ADD_INDICATORS_CURRENCY:
      return {
        ...state,
        indicatorsCurrencies: [action.payload, ...state.indicatorsCurrencies],
      };
    case NAV_STATE_ACTION_TYPES.DEL_SPOT_CURRENCY:
      return {
        ...state,
        spotCurrencies: state.indicatorsCurrencies.filter(
          (currency) => currency != action.payload
        ),
      };
    case NAV_STATE_ACTION_TYPES.DEL_OPTION_CURRENCY:
      return {
        ...state,
        optionCurrencies: state.optionCurrencies.filter(
          (currency) => currency != action.payload
        ),
      };
    case NAV_STATE_ACTION_TYPES.DEL_INDICATORS_CURRENCY:
      return {
        ...state,
        indicatorsCurrencies: state.indicatorsCurrencies.filter(
          (currency) => currency != action.payload
        ),
      };
    default:
      return state;
  }
};

export default AppNavStateReducer;
