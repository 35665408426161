import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import transparent from "../../../assets/images/Transparent.png";
import { Link } from "react-router-dom";
import { api } from "../../../global";

const QuantunaSubHeader = (props) => {
  // define a thin div here that will carry the customers uuid for corresponding email/email
  // this will be used to query customer specific data from the backend
  // define a ref to hold the email value
  const emailRef = useRef();
  const [userApiKey, setUserApiKey] = useState(
    localStorage.getItem("apikey") != null
      ? localStorage.getItem("apikey")
      : undefined
  );
  const [userEmail, setUserEmail] = useState(
    localStorage.getItem("email") != null
      ? localStorage.getItem("email")
      : undefined
  );

  const [userApiKey_Local, setUserApiKey_Local] = useState(
    localStorage.getItem("apikey")
  );
  const [userEmail_Local, setUserEmail_Local] = useState(
    localStorage.getItem("email")
  );

  const ConnectTheAccount = (props) => {
    console.log(
      "Will connect or establish account for email - " + emailRef.current.value
    );

    if (emailRef.current.value == "") {
      return;
    }

    // connectWithApiKey
    var url = api.ssa.url + "/" + api.ssa.connectWithApiKey;
    axios
      .post(
        url,
        {
          email: emailRef.current.value,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data);

        // retrieve data from response
        const data = response.data;

        // if the response is success, then add the api-key to the local-store and also update the sub-header to show the api-key/email instead of the inputs
        console.info(
          "Setting the values from response now",
          data,
          data.api_key,
          data.email
        );

        setUserApiKey(data.api_key);
        setUserEmail(data.email);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // lets make sure that local-storage gets these values as soon as user establishes them
  useEffect(() => {
    console.info(
      "user apikey and email got updated, setting the localstorage values here"
    );
    // on changing the api-key and email states, set them in the local storage so that they become available across the pages
    if (userApiKey != "") {
      localStorage.setItem("apikey", userApiKey);
      localStorage.setItem("email", userEmail);
    }

    // set the values for the local store too
    setUserApiKey_Local(localStorage.getItem("apikey"));
    setUserEmail_Local(localStorage.getItem("email"));
  }, [userApiKey, userEmail]);

  return (
    <>
      <div className="subheader">
        {/* a simple div container containing either a text element or an input box and button to initialize the session */}
        {userApiKey_Local === "undefined" && (
          <div className="subheader--container">
            <input
              type="text"
              id="email"
              name="email"
              ref={emailRef}
              className="subheader--container--input-box"
              placeholder="full-email"
            />
            <button
              key={"connect"}
              className="subheader--container--connect"
              onClick={ConnectTheAccount}
            >
              Connect
            </button>
          </div>
        )}

        {/* if the user apikey is already established, then simply display that */}
        {userApiKey_Local != "undefined" && (
          <div className="subheader--container">
            <span className="subheader--container--apikey">
              {userApiKey_Local}
            </span>
            <span className="subheader--container--email">
              {userEmail_Local}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const QuantunaHeader = (props) => {
  return (
    // This will have two parts to it => one visible on mobile devices and the other one visible on desktop devices
    // building the mobile divice navigation first here
    <>
      {/* This is mobile navigation */}
      <div className="navigation">
        {/* navigation div container */}
        <input
          type="checkbox"
          className="navigation__checkbox"
          id="navi-toggle"
        />
        <label htmlFor="navi-toggle" className="navigation__button">
          <span className="navigation__icon">&nbsp;</span>
        </label>
        <div className="navigation__background">&nbsp;</div>

        <nav className="navigation__nav">
          <ul className="navigation__list">
            <li className="navigation__item">
              <Link to="/" className="navigation__link">
                <img
                  className="navigation-full__logo"
                  alt="Quantuna"
                  src={transparent}
                />
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/options" className="navigation__link">
                Analytics
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/options" className="navigation__link">
                Screener
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/pricer" className="navigation__link">
                Pricer
              </Link>
            </li>
            <li className="navigation__item">
              <Link to="/contact_us" className="navigation__link">
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* This is larger device navigation */}
      <div className="navigation-full">
        <div className="navigation-full__background">&nbsp;</div>
        <nav className="navigation-full__nav">
          <ul className="navigation-full__list">
            <li className="navigation-full__item  navigation-full__item_left">
              <Link to="/" className="navigation-full__link">
                <img
                  className="navigation-full__logo"
                  alt="Quantuna"
                  src={transparent}
                />
              </Link>
            </li>
            <li className="navigation-full__item  navigation-full__item_left">
              <Link to="/analytics" className="navigation-full__link">
                Analytics
              </Link>
            </li>
            {/* <li className="navigation-full__item  navigation-full__item_left">
              <Link to="/options" className="navigation-full__link">
                Options
              </Link>
            </li> */}
            <li className="navigation-full__item  navigation-full__item_left">
              <Link to="/screener" className="navigation-full__link">
                Screener
              </Link>
            </li>
            <li className="navigation-full__item  navigation-full__item_left">
              <Link to="/pricer" className="navigation-full__link">
                Pricer
              </Link>
            </li>
            <li className="navigation-full__item navigation-full__item_right">
              <Link to="/contact_us" className="navigation-full__link">
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <QuantunaSubHeader />
    </>
  );
};

export default QuantunaHeader;
