// this file will contain code for landing of the dashboard page
// show a list of all public and private dashboards
// provide functionality for the user to  build a new dashboard and be able to share it with a group or publically
import React from "react";
import QuantunaHeader from "../../layouts/layout-components/header/QuantunaHeader";
import Footer from "../../layouts/layout-components/footer/Footer";
import { useParams } from "react-router";
import { QuantunaOptionSingleChartLandingInner } from "./components/quantuna_landing/option_single_chart_view";
import { decode } from "js-base64";

const QuantunaOptionSingleChartLanding = () => {
  // option charts will mostly depend on [instrument-name, current-date, other-selected-attributes]
  // [chart_id : VolatalitesAgainstStrikeV2_ID], [underlying_index : BTC-31MAR23], [iv : markIV], [strike_or_delta : strike], [coin : BTC]
  // chart_id/:underlying_index/:iv/:strikeOrDelta/:coin
  const { chart_id, underlying_index, iv, strikeOrDelta, coin, display_type } =
    useParams();
  console.log(
    "Loading the quantuna options-single-chart-landing page ...",
    chart_id,
    underlying_index,
    iv,
    strikeOrDelta,
    coin,
    display_type
  );

  console.info(
    "Printing the url parameters receieved",
    chart_id,
    underlying_index,
    iv,
    strikeOrDelta,
    coin
  );

  // make the object and pass it with name instrument so that it gets to the V2 volatility chart for rendering
  const instrument = {
    underlyingIndex: underlying_index,
    iv: iv,
    strikeOrDelta: strikeOrDelta,
    display_type: display_type,
    coin: coin,
    instrumentName: coin,
    instrument_base: coin,
    base: coin,
    container_width: "80vw",
  };

  // the charts expect the parameters in certain formats, this external wrapper ensures to process the url-parameters before passing them down to actual chart processor
  // attrs need not be used for webpage indexing so this can simply be comma separated list of keyvalue pairs, base64 encoded

  return (
    <div>
      <QuantunaHeader />
      <QuantunaOptionSingleChartLandingInner
        chartId={chart_id}
        instrument={instrument}
        instrument_base={coin}
      />
      <Footer />
    </div>
  );
};

export default QuantunaOptionSingleChartLanding;
