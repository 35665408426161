export const yyyymmdd_today = () => {
  // todays date
  var today = new Date();
  var y = today.getFullYear();
  var m = today.getMonth() + 1;
  var d = today.getDate();
  m < 10 && (m = "0" + m);
  d < 10 && (d = "0" + d);
  var today_str = y + "" + m + "" + d;
  return today_str;
};
