import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { api } from "../../../global";

const GetAllOptionContracts = (props) => {
  const setAllContractsData = props.setAllContractsData;
  const setOptionPriceInParamsError = props.setOptionPriceInParamsError;

  // construct the url
  var url = api.ssa.url + "/" + api.ssa.getAllCurrentQuantunaOptions;
  console.log("Gettig all active/current options from server now - " + url);

  axios
    .post(
      url,
      {
        current_only: props.current_only,
        underlying: props.underlying,
        option_calculation_type: props.option_calculation_type,
        apikey:
          localStorage.getItem("apikey") == null
            ? "GLOBAL"
            : localStorage.getItem("apikey"),
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    )
    .then((response) => {
      // retrieve data from response
      const data = response.data;
      setAllContractsData(data);

      setOptionPriceInParamsError(
        "Received response from the server for pricing request" + data.length
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

const SingleOptionPricesInput_Row = (props) => {
  var single_contract_data = props.single_contract_data;
  return single_contract_data.map((a) => (
    <div
      key={a.contract_name + "input"}
      className="section-single-option-pricer__container--row section-single-option-pricer__container--row--input"
    >
      {/* The next row is a display of the header for each of the columns under input and output */}
      {/* all these cells will occupy equal space in the grid and data will follow similarly */}
      <div className="section-single-option-pricer__container--row--cell">
        {a.contract_name}
      </div>
      <div className="section-single-option-pricer__container--row--cell">
        {a.notional}
      </div>
      <div className="section-single-option-pricer__container--row--cell">
        {a.buy_or_sell}
      </div>
      <div className="section-single-option-pricer__container--row--cell">
        {a.option_calculation_type}
      </div>
      <div className="section-single-option-pricer__container--row--cell">
        {a.option_type}
      </div>
      <div className="section-single-option-pricer__container--row--cell">
        {a.strike}
      </div>
      <div className="section-single-option-pricer__container--row--cell">
        {a.expiry_date}
      </div>
    </div>
  ));
};

const SingleOptionPricesOutput_Row = (props) => {
  var single_contract_data = props.single_contract_data;
  var contract_price = props.contract_price;
  return (
    <>
      {!contract_price && (
        <div
          key={contract_price.contract_name + "output"}
          className="section-single-option-pricer__container--row section-single-option-pricer__container--row--output"
        >
          {/* The next row is a display of the header for each of the columns under input and output */}
          {/* all these cells will occupy equal space in the grid and data will follow similarly */}
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
        </div>
      )}

      {contract_price && contract_price.length > 0 && (
        <div
          key={contract_price[0].contract_name + "output"}
          className="section-single-option-pricer__container--row section-single-option-pricer__container--row--output"
        >
          {/* The next row is a display of the header for each of the columns under input and output */}
          {/* all these cells will occupy equal space in the grid and data will follow similarly */}
          <div className="section-single-option-pricer__container--row--cell">
            {contract_price[0].implied_vol}
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            {contract_price[0].price}
          </div>
          <div
            className="section-single-option-pricer__container--row--cell"
            data-negative={contract_price[0].delta < 0}
          >
            {contract_price[0].delta}
          </div>
          <div
            className="section-single-option-pricer__container--row--cell"
            data-negative={contract_price[0].theta < 0}
          >
            {contract_price[0].theta}
          </div>
          <div
            className="section-single-option-pricer__container--row--cell"
            data-negative={contract_price[0].gamma < 0}
          >
            {contract_price[0].gamma}
          </div>
          <div
            className="section-single-option-pricer__container--row--cell"
            data-negative={contract_price[0].vega < 0}
          >
            {contract_price[0].vega}
          </div>
          <div
            className="section-single-option-pricer__container--row--cell"
            data-negative={contract_price[0].rho < 0}
          >
            {contract_price[0].rho}
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
          <div className="section-single-option-pricer__container--row--cell">
            --
          </div>
        </div>
      )}
    </>
  );
};

export const QuantunaSingleOptionPrices_Inner = (props) => {
  const coinData = props.coinData;

  // by default select BTC to build charts
  const [selectedCoin, setSelectedCoin] = useState("BTC");

  // use a state variable to set the error message
  const [optionPriceInParamsError, setOptionPriceInParamsError] = useState("");

  // create a state object to keep all the contract data
  // this state will be refreshed at the component load as well as everytime user prices a new contract
  const [allContractsData, setAllContractsData] = useState([]);
  // this state variable is used to simply trigger the all contract data refresh using the effect hook, simply increase the value by 1
  const [refreshContractState, setRefreshContractState] = useState(1);
  // show all active or just for today's contracts
  const [showAllActiveContracts, setShowAllActiveContracts] = useState(false);

  // following states are used when user hits the price it button
  const [singleContractsData, setSingleContractsData] = useState([]);
  const [contractPriceData, setContractPriceData] = useState([]);

  // invoke the get all option contracts here so that the grid is populated properly
  // GetAllOptionContracts({
  //   setAllContractsData: setAllContractsData,
  //   current_only: true,
  //   underlying: selectedCoin,
  //   option_calculation_type: "EUR",
  //   setOptionPriceInParamsError: setOptionPriceInParamsError,
  // });

  // set the selected coin on clicking the coin
  const onCoinClickHandler = (event) => {
    console.log("Clicked the button - " + event.target.value);
    setSelectedCoin(event.target.value);
  };

  // create ref objects to handle the values inputted by the user
  const allCurrentRef = useRef();
  const excTypeRef = useRef();
  const optTypeRef = useRef();
  const strikeRef = useRef();
  const expiryRef = useRef();
  const buySellRef = useRef();
  const notionalRef = useRef();
  var today = new Date();
  var today_str =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  var dum_arr = [1, 2, 3, 4];

  const PriceTheOption = (props) => {
    console.log("Pricing option requested");
    console.info(excTypeRef.current.value);
    console.info(optTypeRef.current.value);
    console.info(strikeRef.current.value);
    console.info(expiryRef.current.value);
    console.info(buySellRef.current.value);
    console.info(notionalRef.current.value);

    // checking for an entry to all the required parameters
    if (
      excTypeRef.current.value == "" ||
      optTypeRef.current.value == "" ||
      strikeRef.current.value == "" ||
      expiryRef.current.value == ""
    ) {
      var err_msg =
        "You must input all the required parameters [Excercise Type, Option Type, Strike, Expiry] to request price";
      console.log(err_msg);
      setOptionPriceInParamsError(err_msg);
      return;
    }

    setOptionPriceInParamsError("Sending the price initiation request");
    // make two calls to the backend
    // (1) send a message to catserverr to register the new option contract
    var url = api.ssa.url + "/" + api.ssa.priceSingleOption;
    console.log(
      "Sending the price initiation request to the api server now - " + url
    );
    // make the axios call to request the price here
    axios
      .post(
        url,
        {
          exercise_type: excTypeRef.current.value,
          option_type: optTypeRef.current.value,
          strike: strikeRef.current.value,
          expiry_date: expiryRef.current.value,
          buy_sell: buySellRef.current.value,
          notional: notionalRef.current.value,
          asset: selectedCoin,
          apikey:
            localStorage.getItem("apikey") == null
              ? "GLOBAL"
              : localStorage.getItem("apikey"),
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data);

        // retrieve data from response
        const data = response.data;
        setSingleContractsData(data);

        setOptionPriceInParamsError(
          "Received response from the server for pricing request - " +
            data.length
        );

        // (2) process the response from the catserverr and then request the price from the python service
        console.log("Response recieved from the remote api server");
        // priceSingleOptionAnalyticsService => {asset}/{exercise_type}/{option_type}/{strike}/{expiry_date}
        // pyAnalurl
        const asset = selectedCoin;
        const exercise_type = excTypeRef.current.value;
        const option_type = optTypeRef.current.value;
        const strike = strikeRef.current.value;
        const expiry_date = expiryRef.current.value;
        var anal_url =
          api.ssa.pyAnalurl +
          "/" +
          `opcl/price/${asset}/${exercise_type}/${option_type}/${strike}/${expiry_date}`;

        console.info(
          "Requesting price for option from - ",
          anal_url,
          singleContractsData
        );
        axios
          .get(anal_url, {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            console.log(response);
            console.log(response.data);

            // retrieve data from response
            const data = response.data;
            console.log(data["price"][0]);
            // set the contract price data
            data && data["price"] && setContractPriceData(data["price"]);

            /**
             *
             */

            setOptionPriceInParamsError(
              "Received price response from the server" + data
            );

            // (4) At this point, also get all available option contracts from database and refresh the second table
            // update the refresh state value so that effect hook can refresh
            setRefreshContractState(refreshContractState + 1);
          })
          .catch((error) => {
            console.error(error);
          });

        // (3) Request the price from python calc server
        // setOptionPriceInParamsError("Retrieving the price from the remote server");
        // console.log("Retrieving the price from the remote python api server");
        // setOptionPriceInParamsError("");
      })
      .catch((error) => {
        console.error(error);
      });

    // (1.1) Check for the error message
  };

  // run the refresh-all-contracts data use-effect hook
  useEffect(() => {
    var url = api.ssa.url + "/" + api.ssa.getAllCurrentQuantunaOptions;
    console.log("Gettig all active/current options from server now - " + url);

    axios
      .post(
        url,
        {
          all_active_contracts: showAllActiveContracts,
          underlying: selectedCoin,
          option_calculation_type: "EUR",
          apikey:
            localStorage.getItem("apikey") == null
              ? "GLOBAL"
              : localStorage.getItem("apikey"),
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        // retrieve data from response
        const data = response.data;
        console.log("Receieved all contract data from server", data);
        setAllContractsData(data);

        setOptionPriceInParamsError("Refreshed Todays Option Prices ");
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedCoin, showAllActiveContracts, refreshContractState]);

  // useEffect to reset all the input values
  useEffect(() => {
    // reset all the refs
    strikeRef.current.value = "";
    expiryRef.current.value = "";
    notionalRef.current.value = "";
  }, [selectedCoin, refreshContractState]);

  return (
    <>
      <section className="section-single-option-pricer">
        {/* Static Menu */}
        <div className="section-single-option-pricer__static-menu">
          {/* Global Inputs */}
          <div className="section-single-option-pricer__static-menu--global-inputs">
            Global Inputs
          </div>
          {/* Valuation Date */}
          <div className="section-single-option-pricer__static-menu__valuation-date">
            <div className="section-single-option-pricer__static-menu__valuation-date__cap">
              Valution Date
            </div>
            <div className="section-single-option-pricer__static-menu__valuation-date__val">
              {today_str}
            </div>
          </div>
          {/* Model */}
          <div className="section-single-option-pricer__static-menu__model">
            <div className="section-single-option-pricer__static-menu__model__cap">
              Model
            </div>
            <div className="section-single-option-pricer__static-menu__model__val">
              Analytical
            </div>
          </div>
          <div className="section-single-option-pricer__static-menu__coins">
            <div className="section-single-option-pricer__static-menu__coins__cap">
              Coin
            </div>
            <div className="section-single-option-pricer__static-menu__coins__val">
              {coinData &&
                coinData.map((d, idx) => (
                  <div
                    className="section-single-option-pricer__coingroup"
                    key={idx + d.coinSymbol + "optioncoins" + "div1"}
                  >
                    <input
                      type="radio"
                      className="section-single-option-pricer__coingroup__input"
                      value={d.coinSymbol.toUpperCase()}
                      id={idx + d.coinSymbol + "optioncoins"}
                      name="coin"
                      checked={
                        selectedCoin == d.coinSymbol.toUpperCase()
                          ? "checked"
                          : ""
                      }
                      onChange={onCoinClickHandler}
                    />
                    <label
                      htmlFor={idx + d.coinSymbol + "optioncoins"}
                      className="section-single-option-pricer__coingroup__label"
                    >
                      {/* <img
                        className="section-single-option-pricer__coingroup__label__img"
                        src={`data:image/png;base64,${d.coinImage}`}
                      /> */}
                      {d.coinSymbol}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="section-single-option-pricer--error">
          {optionPriceInParamsError}
        </div>

        {/* START: make a container for the lower section */}
        <div className="section-single-option-pricer__container">
          {/* The container has two sections to it (1) the input and the (2) the output */}
          {/* START/END: Input section */}
          <div className="section-single-option-pricer__container--input">
            {/* START/END: --row--long-header-left */}
            <div className="section-single-option-pricer__container--row bg-light-grey">
              <div className="section-single-option-pricer__container--row--cell section-single-option-pricer__container--row--long-cell">
                Input
              </div>
            </div>
            {/* START/END: --row--long-header-left */}

            {/* START/END: --row */}
            <div className="section-single-option-pricer__container--row section-single-option-pricer__container--row--input bg-light-grey">
              {/* The next row is a display of the header for each of the columns under input and output */}
              {/* all these cells will occupy equal space in the grid and data will follow similarly */}
              <div className="section-single-option-pricer__container--row--cell">
                ID
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Notional
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Buy/Sell
              </div>
              <div className="section-single-option-pricer__container--row--cell required">
                Exercise Type
              </div>
              <div className="section-single-option-pricer__container--row--cell required">
                Option Type
              </div>
              <div className="section-single-option-pricer__container--row--cell required">
                Strike
              </div>
              <div className="section-single-option-pricer__container--row--cell required">
                Expiry
              </div>
            </div>
            {/* START/END: --row */}

            {/* START/END: Input Menu Items */}
            <div className="section-single-option-pricer__container--row section-single-option-pricer__container--row--input bg-lighter-grey">
              <div className="section-single-option-pricer__container--row--cell">
                &nbsp;
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                <input
                  type="text"
                  id="notional"
                  name="notional"
                  ref={notionalRef}
                  className="section-single-option-pricer--input-box section-single-option-pricer--input-box-small"
                  placeholder="100"
                />
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                <select
                  name="buy_sell"
                  id="buy_sell"
                  ref={buySellRef}
                  className="section-single-option-pricer--select"
                >
                  <option value="B">BUY</option>
                  <option value="S">SELL</option>
                </select>
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                <select
                  name="excercise_type"
                  id="excercise_type"
                  ref={excTypeRef}
                  className="section-single-option-pricer--select"
                >
                  <option value="EUR">European</option>
                </select>
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                <select
                  name="option_type"
                  id="option_type"
                  ref={optTypeRef}
                  className="section-single-option-pricer--select"
                >
                  <option value="P">PUT</option>
                  <option value="C">CALL</option>
                </select>
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                <input
                  type="text"
                  id="strike"
                  name="strike"
                  ref={strikeRef}
                  className="section-single-option-pricer--input-box"
                  placeholder="50000"
                />
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                <input
                  type="text"
                  id="expiry"
                  name="expiry"
                  ref={expiryRef}
                  className="section-single-option-pricer--input-box"
                  placeholder="YYYYMMDD"
                />
              </div>
            </div>
            {/* START/END: Input Menu Items */}

            {/* START/END: input section of the data rows */}
            <SingleOptionPricesInput_Row
              single_contract_data={singleContractsData}
            />
            {/* START/END: input section of the data rows */}
          </div>
          {/* START/END: Input section */}

          {/* START/END: Output section */}
          <div className="section-single-option-pricer__container--output">
            {/* START/END: --row--long-header-right */}
            <div className="section-single-option-pricer__container--row section-single-option-pricer__container--row--long-header-right bg-light-grey">
              <div className="section-single-option-pricer__container--row--cell section-single-option-pricer__container--row--long-cell">
                Output
              </div>
            </div>
            {/* START/END: --row--long-header-right */}

            {/* START/END: --row */}
            <div className="section-single-option-pricer__container--row section-single-option-pricer__container--row--output bg-light-green">
              <div className="section-single-option-pricer__container--row--cell">
                Implied Vol
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Price
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Delta
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Theta
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Gamma
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Vega
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Rho
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Vanna
              </div>
              <div className="section-single-option-pricer__container--row--cell">
                Volga
              </div>
            </div>
            {/* START/END: --row */}

            {/* START/END: Add the div for price-it button */}
            <div className="section-single-option-pricer__container--row section-single-option-pricer__container--row--long-header-right bg-lighter-grey">
              <div className="section-single-option-pricer__container--row--cell section-single-option-pricer__container--row--long-cell">
                <button
                  key={"priceit"}
                  className="section-single-option-pricer--priceit"
                  onClick={PriceTheOption}
                >
                  Price It
                </button>
              </div>
            </div>
            {/* START/END: Add the div for price-it button */}

            {/* START/END: input section of the data rows */}
            <SingleOptionPricesOutput_Row
              all_contract_data={singleContractsData}
              contract_price={contractPriceData}
            />
            {/* START/END: input section of the data rows */}
          </div>
          {/* START/END: Output section */}
        </div>
        {/* END: make a container for the lower section */}
      </section>

      {/* Section (2): Used for displaying all the options priced today */}
      {
        <QuantunaSingleOptionPrices_DisplayAllToday
          allContractsData={allContractsData}
          setShowAllActiveContracts={setShowAllActiveContracts}
        />
      }
    </>
  );
};

export const QuantunaSingleOptionPrices_DisplayAllToday = (props) => {
  const all_contract_data = props.allContractsData;
  const allCurrentRef = useRef();

  const refreshAllContractData = () => {
    console.info(
      "Need to refresh the value of the all-current options",
      allCurrentRef.current.checked
    );

    props.setShowAllActiveContracts(allCurrentRef.current.checked);
  };
  return (
    <section className="section-display-todays-options">
      <div className="section-display-todays-options__container">
        <div className="section-display-todays-options__container--row section-display-todays-options__container--row--title">
          <div className="section-display-todays-options__container--row--cell section-display-todays-options__container--row--long-cell">
            <span className="section-display-todays-options__container--titletext">
              Today's Option Prices
            </span>
          </div>
          <div className="section-display-todays-options__container--row--cell section-display-todays-options__container--row--allactive-cell">
            <label>
              <input
                type="checkbox"
                className="section-display-todays-options__container__allcurrent"
                ref={allCurrentRef}
                onChange={refreshAllContractData}
              />
              Active
            </label>
          </div>
        </div>

        <div
          className="section-display-todays-options__container--row section-display-todays-options__container--row--header"
          key="display-header"
        >
          {[
            "ID",
            "Notional",
            "Buy/Sell",
            "Excercise Type",
            "Option Type",
            "Strike",
            "Expiry",
            "Implied Vol",
            "Price",
            "Delta",
            "Theta",
            "Gamma",
            "Vega",
            "Rho",
            "Vanna",
            "Volga",
            "Creation Date",
          ].map((a) => (
            <div
              className="section-display-todays-options__container--row--cell section-display-todays-options__container--row--cell--header"
              key={"displayheadercell" + a}
            >
              {a}
            </div>
          ))}
        </div>

        {all_contract_data.map((a) => (
          <div
            className="section-display-todays-options__container--row"
            key={
              "display-data-row" +
              a.contract_name +
              a.option_calculation_type +
              a.creation_date_int +
              a.data.contract_id +
              a.data.calculation_date
            }
          >
            <div className="section-display-todays-options__container--row--cell">
              {a.contract_name}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.notional}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.buy_or_sell}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.option_calculation_type}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.option_type}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.strike}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.expiry_date}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.data.implied_vol}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.data.price}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.delta < 0}
            >
              {a.data.delta}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.theta < 0}
            >
              {a.data.theta}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.gamma < 0}
            >
              {a.data.gamma}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.vega < 0}
            >
              {a.data.vega}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.rho < 0}
            >
              {a.data.rho}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.vanna < 0}
            >
              {a.data.vanna}
            </div>
            <div
              className="section-display-todays-options__container--row--cell"
              data-negative={a.data.volga < 0}
            >
              {a.data.volga}
            </div>
            <div className="section-display-todays-options__container--row--cell">
              {a.creation_date_int}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
