import React, { useState, useEffect, Suspense } from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { useLoadMappedChartListQuery } from "../../../api/SettingsApi";
import { ChartsFactory } from "../../components/charts/ChartsFactory";
import {
  QuantunaChartTitle,
  QuantunaSelectedContainerPinit,
  QuantunaChart,
} from "./basic/BasicChartComponents";

export const WrappedChartConstruction = (props) => {
  // pass down all the properties coming from the call and let the wrapper take care of the simple-chart-construction
  // basically, wrapper is another loop to create chart-cards but it delegates the chart-card generation mechanism to the wrapper-chart (which knows which template to use to generate the instances)
  console.info("Getting wrapped chart template for", props.chart_attrs);

  return (
    <>
      <ChartsFactory
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
        template={props.chart_attrs.chart_template}
        category={props.chart_attrs.chart_category}
        idx={props.idx}
      />
    </>
  );
};

export const SimpleChartConstruction = (props) => {
  // DEFINING THE DISPLAY PROPERTY ON TOP
  // by default make the chart visible for now
  const [onDisplay, setOnDisplay] = useState("chart");
  const [buySellHoldSignal, setBuySellHoldSignal] = useState(
    "sell-removethispart"
  ); // set this to buy, sell, hold from the code and that shall show appropriate bulb, set it to anything else to take it off
  const [pinTheChart, setPinTheChart] = useState("none");

  // based on what value we get in the toggle, flip the display
  // console.log("on-display element for", props.title, onDisplay);
  const toggleChartOrInfoDisplay = (val) => {
    console.log("Setting the visibility from ", onDisplay, "to", val);
    setOnDisplay(val);
    console.log("New ondisplay for simple-construction is - ", onDisplay);
  };

  const togglePinTheChart = () => {
    setPinTheChart(pinTheChart === "none" ? "pinit" : "none");
    console.log("pinning to - " + pinTheChart);
  };

  // d["category"] = d.chart_category;
  // d["title"] = d.chart_description_1;
  // d["template"] = d.chart_template;

  console.info(
    "Generating chart for instrument ======>",
    props.instrument,
    props.chart_attrs
  );

  return (
    <>
      {/* Pulling the data into data-factory one level up so that we can use that in chart as well as table display */}
      <QuantunaChart
        key={props.instrument.instrument_base + "_" + props.idx + "_chart"}
        card_height={props.card_height}
        display_type={onDisplay}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    </>
  );
};

const ChartsForAssetForCoreDashboard = (props) => {
  const mappedChartList = useLoadMappedChartListQuery(); // spot, option, indicators

  console.log("Chart list from the database ", mappedChartList);

  // based on the idx value, the charts needs to be wrapped in rows and columns
  // each card will be wrapped in a col
  // we can use a toggle variable based on the idx, and

  // lets make the array a group of two, three or four
  // don't expect a calm span less than 3, so handling the 3 scenarios with different variables instead of generalizing it.
  const mappedChartListGroups = [];
  let chartPairs = [];
  let chartTriplets = [];
  let chartQuadruplets = [];
  mappedChartList.data &&
    mappedChartList.data[props.category] &&
    mappedChartList.data[props.category].map((mcl) => {
      if (mcl.chart_col_span == 12) {
        mappedChartListGroups.push([mcl]);
      } else if (mcl.chart_col_span == 6) {
        if (chartPairs.length == 2) {
          mappedChartListGroups.push(chartPairs);
          chartPairs = [];
        }
        chartPairs.push(mcl);
      } else if (mcl.chart_col_span == 4) {
        if (chartTriplets.length == 3) {
          mappedChartListGroups.push(chartTriplets);
          chartTriplets = [];
        }
        chartTriplets.push(mcl);
      } else if (mcl.chart_col_span == 3) {
        if (chartQuadruplets.length == 4) {
          mappedChartListGroups.push(chartQuadruplets);
          chartQuadruplets = [];
        }
        chartQuadruplets.push(mcl);
      }
    });

  if (chartPairs.length > 0) {
    mappedChartListGroups.push(chartPairs);
  }
  if (chartTriplets.length > 0) {
    mappedChartListGroups.push(chartTriplets);
  }
  if (chartQuadruplets.length > 0) {
    mappedChartListGroups.push(chartQuadruplets);
  }

  console.info(JSON.stringify(mappedChartListGroups));

  console.log("mapped chart list ", mappedChartList);

  return (
    mappedChartListGroups &&
    mappedChartListGroups.map((group, idx) => {
      const sayHello = () => console.log("saying hello here");

      // if any of the two charts in the group is the vol-surface chart, set the height as 600px else set it to 400
      var card_height = 400;
      if (
        (group.length == 1 && group[0].chart_type == "surface") ||
        (group.length == 2 &&
          (group[0].chart_type == "surface" ||
            group[1].chart_type == "surface"))
      ) {
        card_height = 675;
      }

      return (
        <Row key={props.instrument.base + "_" + idx}>
          <>
            {group.map((item, i) => {
              return (
                <Col md={item.chart_col_span}>
                  <Card>
                    <CardBody>
                      {/*simple - chart - construction goes here*/}
                      <SimpleChartConstruction
                        idx={idx}
                        show_pop_out={true}
                        instrument={props.instrument}
                        chart_attrs={item}
                      />
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </>
        </Row>
      );
    })
  );
  // return <ChartsFactory asset={props.instrumentName} instrument={props.instrument} category={props.category} />
  // return mappedChartList.data && mappedChartList.data[props.category] && mappedChartList.data[props.category].map((d, idx) => <h1 key={d.chart_id}>{idx} - {props.instrument.base} - {d.name} -- {d.template}</h1>);
};

export const AssetCardForCoreDashboard = (props) => {
  return (
    <div key={props.instrument.base + props.idx + "_div"}>
      {/* <Alert color="dark" key={props.instrument.base + props.idx + "_alert"}>
        <span className="alert-instrument-label">{props.instrument.base}</span>
      </Alert> */}
      <ChartsForAssetForCoreDashboard
        instrument={props.instrument}
        category={props.category}
      />
      {/*<TestChart instrument={props.instrument} category={props.category} /> */}
    </div>
  );
};
