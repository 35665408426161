import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useGetTradedAssetQuery } from "../../../../api/InstrumentsApi";
import { useLoadChartListForIdQuery } from "../../../../api/SettingsApi";
import { SimpleChartConstruction } from "../../../dashboard/components/AssetCardForCoreDashboard";
import Spinner from "../../../spinner/Spinner";

export const QuantunaOptionSingleChartLandingInner1 = (props) => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.coin.toUpperCase()
  );
  const [selectedChartId, setSelectedChartId] = useState(props.chartId);

  // get the chart information from the backend

  const category = "not-required";

  const selectedAsset = useGetTradedAssetQuery([selectedCurrency + "USDT"]);
  const selectedChartList = useLoadChartListForIdQuery(selectedChartId);

  console.info(
    "Selected chart/asset object is",
    selectedChartList,
    selectedAsset
  );

  var tradedInstrument =
    selectedAsset && selectedAsset.isSuccess
      ? selectedAsset.data && selectedAsset.data[0]
      : undefined;

  var selectedChart =
    selectedChartList && selectedChartList.isSuccess
      ? selectedChartList.data
      : undefined;

  var card_height = "80vh";

  return (
    <div style={{ marginTop: "3.4em" }} className="page-wrapper d-block">
      {/* <div className="quantuna-landing-top-menu-options">This is xyz</div> */}
      <div className="page-content container-fluid">
        <div className="quantuna-landing-body">
          <Card>
            <CardBody>
              {/*simple - chart - construction goes here*/}
              {tradedInstrument && selectedChart && (
                <SimpleChartConstruction
                  idx={1}
                  instrument={tradedInstrument}
                  instrument_base={tradedInstrument.base}
                  category={selectedChart.category}
                  card_height={card_height}
                  title={selectedChart.description_1}
                  template={selectedChart.template}
                  chart_id={selectedChart.chart_id}
                  chart_attrs={selectedChart}
                />
              )}
              {!tradedInstrument && !selectedChart && <Spinner />}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export const QuantunaOptionSingleChartLandingInner = (props) => {
  const selectedCurrency = props.instrument.underlyingIndex.toUpperCase();
  const selectedChartId = props.chartId;

  // get the chart information from the backend
  const selectedChartList = useLoadChartListForIdQuery(selectedChartId);

  var selectedChart =
    selectedChartList && selectedChartList.isSuccess
      ? selectedChartList.data
      : undefined;

  var card_height = "80vh";

  console.info(
    "Selected chart/asset object is",
    selectedChart,
    selectedCurrency,
    props.instrument
  );

  /*template={props.template}
          instrument={props.instrument}
          category={props.category}
          card_height={props.card_height}
          display_type={onDisplay}
          underlying_index={props.underlying_index}
          chart_attrs={props.chart_attrs}
  }; */

  return (
    <div style={{ marginTop: "3.4em" }} className="page-wrapper d-block">
      {/* <div className="quantuna-landing-top-menu-options">This is xyz</div> */}
      <div className="page-content container-fluid">
        <div className="quantuna-landing-body">
          <Card>
            <CardBody>
              {/* we simply pass all the parameters required to help chart-factory build the chart */}
              {/* chart body to come here {selectedChartId}:{selectedCurrency}{" "} */}
              {selectedChart && (
                <SimpleChartConstruction
                  idx={0}
                  card_height={card_height}
                  chart_attrs={selectedChart}
                  instrument={props.instrument}
                  display_type={"chart"}
                />
              )}
              {!selectedChart && <Spinner />}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
