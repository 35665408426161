import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { api } from "../../../global";
import {
  useGetAllCurrentQuantunaOptionsQuery,
  useRequestSingleOptionPriceCalcQuery,
} from "../../../api/OptionsApi";

const QuantunaOptionPricesDisplayHeader_Compact = (props) => {
  // return just the header display here
  return (
    <>
      {/* results here for {props.option_query} */}
      {/* lets make result rows here which will all be display grid too */}
      {/* option_type, actual_maturity, maturity, price, implied_vol, delta, gamma, vega, theta, rho, strike, expiry_date */}
      <div className="prices-result-container__row prices-result-container__row--header">
        {/* all the result records here */}
        {/* European Options */}
        {/* option_type 	strike 	days_to_maturity 	price 	implied_vol 	delta 	gamma 	vega 	theta 	rho */}
        <div className="prices-result-container__row__cell">option_type</div>
        <div className="prices-result-container__row__cell">
          actual_maturity
        </div>
        <div className="prices-result-container__row__cell">maturity</div>
        <div className="prices-result-container__row__cell">price</div>
        <div className="prices-result-container__row__cell">implied_vol</div>
        <div className="prices-result-container__row__cell">delta</div>
        <div className="prices-result-container__row__cell">gamma</div>
        <div className="prices-result-container__row__cell">vega</div>
        <div className="prices-result-container__row__cell">theta</div>
        <div className="prices-result-container__row__cell">rho</div>

        <div className="prices-result-container__row__cell prices-result-container__row__cell__strike">
          strike
        </div>

        <div className="prices-result-container__row__cell">expiry_date</div>
      </div>
    </>
  );
};

const QuantunaOptionPricesDisplayRow_Compact = (props) => {
  const option_contract_c = props.option_contract;

  /*console.info(
    "Building the option row for display - ",
    props.option_contract,
    option_contract_c,
    option_contract_p
  ); */
  const row_key =
    option_contract_c["contract_name"] + option_contract_c["actual_maturity"];

  return (
    <>
      <div className="prices-result-container__row" key={row_key}>
        {/* option_type, actual_maturity, maturity, price, implied_vol, delta, gamma, vega, theta, rho, strike, expiry_date */}
        <div className="prices-result-container__row__cell" key={row_key + "1"}>
          {option_contract_c["option_type"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "2"}>
          {option_contract_c["actual_maturity"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "3"}>
          {option_contract_c["maturity"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "4"}>
          {option_contract_c["data"] && option_contract_c["data"]["price"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "5"}>
          {option_contract_c["data"] &&
            option_contract_c["data"]["implied_vol"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "6"}>
          {option_contract_c["data"] && option_contract_c["data"]["delta"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "7"}>
          {option_contract_c["data"] && option_contract_c["data"]["gamma"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "8"}>
          {option_contract_c["data"] && option_contract_c["data"]["vega"]}
        </div>
        <div className="prices-result-container__row__cell" key={row_key + "9"}>
          {option_contract_c["data"] && option_contract_c["data"]["theta"]}
        </div>
        <div
          className="prices-result-container__row__cell"
          key={row_key + "10"}
        >
          {option_contract_c["data"] && option_contract_c["data"]["rho"]}
        </div>
        <div
          className="prices-result-container__row__cell"
          key={row_key + "11"}
        >
          {option_contract_c["strike"]}
        </div>
        <div
          className="prices-result-container__row__cell"
          key={row_key + "12"}
        >
          {option_contract_c["expiry_date"]}
        </div>
      </div>
    </>
  );
};

const QuantunaOptionPricesDisplay_Compact = (props) => {
  // get all active-option-contract-record-data (this will come with current data)
  // useGetAllCurrentQuantunaOptionsQuery
  // using the option query, get all the option contract for subscription
  const allOptionContractsQ = useGetAllCurrentQuantunaOptionsQuery(
    props.selectedCoin,
    "EUR"
  );

  const allOptionContracts =
    allOptionContractsQ &&
    allOptionContractsQ.isSuccess &&
    allOptionContractsQ.data
      ? allOptionContractsQ.data
      : undefined;

  console.info(allOptionContracts);
  return (
    <>
      {allOptionContracts &&
        Object.entries(allOptionContracts).map(
          ([option_contract_k, option_contract_v]) => (
            <QuantunaOptionPricesDisplayRow_Compact
              option_contract={option_contract_v}
              key={option_contract_v["contract_name"]}
            />
          )
        )}
    </>
  );
};

export const QuantunaOptionPricesInner_Compact = (props) => {
  // get all the active option contracts and then generate a row record for each
  // the row-records should subscribe to the server side data for receiving real time updates
  // upon data refresh, the row-records should refresh and not the whole page
  // (this design will ensure the refresh part though)

  const coinData = props.coinData;

  // by default select BTC to build charts
  const [selectedCoin, setSelectedCoin] = useState("BTC");

  // set the selected coin on clicking the coin
  const onCoinClickHandler = (event) => {
    console.log("Clicked the button - " + event.target.value);
    setSelectedCoin(event.target.value);
  };

  const [optionQuery, setOptionQuery] = useState("");
  const [optionPriceInParamsError, setOptionPriceInParamsError] = useState("");

  // create ref objects to handle the values inputted by the user
  const excTypeRef = useRef();
  const optTypeRef = useRef();
  const strikeRef = useRef();
  const expiryRef = useRef();
  const buySellRef = useRef();
  const notionalRef = useRef();

  const PriceTheOption = (props) => {
    console.log("Pricing option requested");
    console.info(excTypeRef.current.value);
    console.info(optTypeRef.current.value);
    console.info(strikeRef.current.value);
    console.info(expiryRef.current.value);
    console.info(buySellRef.current.value);
    console.info(notionalRef.current.value);

    // checking for an entry to all the required parameters
    if (
      excTypeRef.current.value == "" ||
      optTypeRef.current.value == "" ||
      strikeRef.current.value == "" ||
      expiryRef.current.value == ""
    ) {
      var err_msg =
        "You must input all the required parameters [Excercise Type, Option Type, Strike, Expiry] to request price";
      console.log(err_msg);
      setOptionPriceInParamsError(err_msg);
      //   return;
    }

    setOptionPriceInParamsError("Sending the price initiation request");
    // make two calls to the backend
    // (1) send a message to catserverr to register the new option contract
    var url = api.ssa.url + "/" + api.ssa.priceSingleOption;
    console.log(
      "Sending the price initiation request to the api server now - " + url
    );
    // make the axios call to request the price here
    axios
      .post(
        url,
        {
          exercise_type: excTypeRef.current.value,
          option_type: optTypeRef.current.value,
          strike: strikeRef.current.value,
          expiry_date: expiryRef.current.value,
          buy_sell: buySellRef.current.value,
          notional: notionalRef.current.value,
          asset: selectedCoin,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setOptionPriceInParamsError(
          "Received response from the server for pricing request"
        );
      })
      .catch((error) => {
        console.error(error);
      });

    // (1.1) Check for the error message

    // (2) process the response from the catserverr and then request the price from the python service
    console.log("Response recieved from the remote api server");

    // (3) Request the price from python calc server
    // setOptionPriceInParamsError("Retrieving the price from the remote server");
    // console.log("Retrieving the price from the remote python api server");
    // setOptionPriceInParamsError("");
  };
  return (
    <main>
      {/* component (0) - add selector for BTC/ETH here */}
      <section className="section-prices-menu">
        {/* menu items to come here */}
        <div className="section-prices-menu__wrapper">
          <div className="section-prices-menu__wrapper__left section-prices-menu__wrapper--captions">
            <div className="section-prices-menu__wrapper--itemgrouped">
              <div className="section-prices-menu__wrapper--itemgrouped__item required">
                Excercise Type
              </div>
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                <select
                  name="excercise_type"
                  id="excercise_type"
                  ref={excTypeRef}
                  className="section-prices-menu__wrapper--itemgrouped__item--select"
                >
                  <option value="EUR">European</option>
                </select>
              </div>
            </div>
            <div className="section-prices-menu__wrapper--itemgrouped">
              <div className="section-prices-menu__wrapper--itemgrouped__item required">
                Option Type
              </div>
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                <select
                  name="option_type"
                  id="option_type"
                  ref={optTypeRef}
                  className="section-prices-menu__wrapper--itemgrouped__item--select"
                >
                  <option value="P">PUT</option>
                  <option value="C">CALL</option>
                </select>
              </div>
            </div>
            <div className="section-prices-menu__wrapper--itemgrouped">
              <div className="section-prices-menu__wrapper--itemgrouped__item required">
                Strike
              </div>
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                <input
                  type="text"
                  id="strike"
                  name="strike"
                  ref={strikeRef}
                  className="section-prices-menu__wrapper--itemgrouped__item--input-box"
                  placeholder="50000"
                />
              </div>
            </div>
            <div className="section-prices-menu__wrapper--itemgrouped">
              <div className="section-prices-menu__wrapper--itemgrouped__item required">
                Expiry
              </div>
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                {/* <DatePicker /> */}
                <input
                  type="text"
                  id="expiry"
                  name="expiry"
                  ref={expiryRef}
                  className="section-prices-menu__wrapper--itemgrouped__item--input-box"
                  placeholder="YYYYMMDD"
                />
              </div>
            </div>
            <div className="section-prices-menu__wrapper--itemgrouped">
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                Buy/Sell
              </div>
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                <select
                  name="buy_sell"
                  id="buy_sell"
                  ref={buySellRef}
                  className="section-prices-menu__wrapper--itemgrouped__item--select"
                >
                  <option value="B">BUY</option>
                  <option value="S">SELL</option>
                </select>
              </div>
            </div>
            <div className="section-prices-menu__wrapper--itemgrouped">
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                Notional
              </div>
              <div className="section-prices-menu__wrapper--itemgrouped__item">
                <input
                  type="text"
                  id="notional"
                  name="notional"
                  ref={notionalRef}
                  className="section-prices-menu__wrapper--itemgrouped__item--input-box"
                  placeholder="100"
                />
              </div>
            </div>
            <div className="section-prices-menu__wrapper--itemgrouped">
              <button
                key={"priceit"}
                className="section-prices-menu__wrapper--priceit"
                onClick={PriceTheOption}
              >
                Price It
              </button>
            </div>
          </div>
          <div className="section-prices-menu__wrapper__right">
            <div
              key={"optioncoins" + "1div"}
              className="section-prices-menu__coinoptions"
            >
              {coinData &&
                coinData.map((d, idx) => (
                  <div
                    className="section-prices-menu__coingroup"
                    key={idx + d.coinSymbol + "optioncoins" + "div1"}
                  >
                    <input
                      type="radio"
                      className="section-prices-menu__coingroup__input"
                      value={d.coinSymbol.toUpperCase()}
                      id={idx + d.coinSymbol + "optioncoins"}
                      name="coin"
                      checked={
                        selectedCoin == d.coinSymbol.toUpperCase()
                          ? "checked"
                          : ""
                      }
                      onChange={onCoinClickHandler}
                    />
                    <label
                      htmlFor={idx + d.coinSymbol + "optioncoins"}
                      className="section-prices-menu__coingroup__label"
                    >
                      {/* <img
                        className="section-prices-menu__coingroup__label__img"
                        src={`data:image/png;base64,${d.coinImage}`}
                      /> */}
                      {d.coinSymbol}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section-prices">
        {/* this is option prices section */}
        {/* adding error handling for missing parameters */}
        {optionPriceInParamsError != "" && (
          <div className="section-prices--error">
            {optionPriceInParamsError}
          </div>
        )}
        {/* component (1) - option-prices-header-display */}
        <div className="prices-result-container">
          <QuantunaOptionPricesDisplayHeader_Compact />
          {/* component (2) - option-prices-row-display */}
          <QuantunaOptionPricesDisplay_Compact selectedCoin={selectedCoin} />
        </div>
      </section>
    </main>
  );
};
