// this file will contain code for landing of the dashboard page
// show a list of all public and private dashboards
// provide functionality for the user to  build a new dashboard and be able to share it with a group or publically
import React from "react";
import QuantunaHeader from "../../layouts/layout-components/header/QuantunaHeader";
import Footer from "../../layouts/layout-components/footer/Footer";
import { useEnrichOptionCoinsCoveredQuery } from "../../api/InstrumentsApi";
import { Spinner } from "reactstrap";
import { QuantunaOptionPricesInner_Compact } from "./components/option_prices_compact";

const QuantunaOptionPrices = () => {
  const coins = ["BTC", "ETH"];

  // lets call a server method and enrich the coins with images
  const enrichedCoins = useEnrichOptionCoinsCoveredQuery(coins);
  console.log("Received enriched coins from service", enrichedCoins);
  const coinData =
    enrichedCoins && enrichedCoins.isSuccess && enrichedCoins.data
      ? enrichedCoins.data
      : undefined;

  return (
    <>
      <QuantunaHeader />
      {<QuantunaOptionPricesInner_Compact coinData={coinData} />}
      {/* {coinData && <QuantunaOptionPricesInner coinData={coinData} />} */}
      {/* {!coinData && <Spinner />} */}
      <Footer />
    </>
  );
};

export default QuantunaOptionPrices;
