// this file will contain code for landing of the dashboard page
// show a list of all public and private dashboards
// provide functionality for the user to  build a new dashboard and be able to share it with a group or publically
import React, { useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import QuantunaHeader from "../../layouts/layout-components/header/QuantunaHeader";
import Footer from "../../layouts/layout-components/footer/Footer";
import { QuantunaOptionsNewLandingInner } from "./components/quantuna_landing/options_landing_view";
import { useEnrichOptionCoinsCoveredQuery } from "../../api/InstrumentsApi";
import { HomePageLanding } from "./components/quantuna_landing/home_page";
// "../../../../layouts/layout-components/footer/Footer";

const QuantunaHomePage = () => {
  const coins = ["BTC", "ETH"];

  // lets call a server method and enrich the coins with images
  const enrichedCoins = useEnrichOptionCoinsCoveredQuery(coins);
  console.log("Received enriched coins from service", enrichedCoins);
  const coinData =
    enrichedCoins && enrichedCoins.isSuccess && enrichedCoins.data
      ? enrichedCoins.data
      : undefined;
  return (
    <>
      <QuantunaHeader />
      <HomePageLanding/>
      <Footer />
    </>
  );
};

export default QuantunaHomePage;
