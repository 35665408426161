import React, { useState } from "react";
import { StraddleAndStrangle } from "./straddle_and_strangle";
import { Butterfly } from "./butterfly";
import { IronCondor } from "./iron_condors";

export const QuantunaOptionScreenerInner = (props) => {
  const coinData = props.coinData;

  // by default select BTC to build charts
  const [selectedCoin, setSelectedCoin] = useState("BTC");

  // set the selected coin on clicking the coin
  const onCoinClickHandler = (event) => {
    console.log("Clicked the button - " + event.target.value);
    setSelectedCoin(event.target.value);
  };

  var today = new Date();
  var today_str =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  return (
    <main>
      <section className="section-screener">
        {/* Static Menu */}
        <div className="section-single-option-pricer__static-menu">
          {/* Global Inputs */}
          <div className="section-single-option-pricer__static-menu--global-inputs">
            Filters
          </div>
          {/* Valuation Date */}
          <div className="section-single-option-pricer__static-menu__valuation-date">
            <div className="section-single-option-pricer__static-menu__valuation-date__cap">
              Valuation Date
            </div>
            <div className="section-single-option-pricer__static-menu__valuation-date__val">
              {today_str}
            </div>
          </div>
          <div className="section-single-option-pricer__static-menu__coins">
            <div className="section-single-option-pricer__static-menu__coins__cap">
              Coin
            </div>
            <div className="section-single-option-pricer__static-menu__coins__val">
              {coinData &&
                coinData.map((d, idx) => (
                  <div
                    className="section-single-option-pricer__coingroup"
                    key={idx + d.coinSymbol + "optioncoins" + "div1"}
                  >
                    <input
                      type="radio"
                      className="section-single-option-pricer__coingroup__input"
                      value={d.coinSymbol.toUpperCase()}
                      id={idx + d.coinSymbol + "optioncoins"}
                      name="coin"
                      checked={
                        selectedCoin == d.coinSymbol.toUpperCase()
                          ? "checked"
                          : ""
                      }
                      onChange={onCoinClickHandler}
                    />
                    <label
                      htmlFor={idx + d.coinSymbol + "optioncoins"}
                      className="section-single-option-pricer__coingroup__label"
                    >
                      {/* <img
                        className="section-single-option-pricer__coingroup__label__img"
                        src={`data:image/png;base64,${d.coinImage}`}
                      /> */}
                      {d.coinSymbol}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* make a subsection for the straddle and strangle */}
        <section className="section-screener--sas">
          {/* we will show total 4 tables for straddle and strangle in this section */}
          <StraddleAndStrangle
            sas_title="StraddleAndStrangle"
            underlying={selectedCoin}
          ></StraddleAndStrangle>
        </section>

        {/* make another subsection for the butterfly */}
        <section className="section-screener--butterfly">
          <Butterfly
            butterfly_title="Butterfly"
            underlying={selectedCoin}
          ></Butterfly>
        </section>

        {/* make another subsection for the iron-condor */}
        <section className="section-screener--ironcondor">
          <IronCondor
            ironcondor_title="Iron Condors"
            underlying={selectedCoin}
          ></IronCondor>
        </section>
      </section>
    </main>
  );
};
