// import { buffer } from "stream/consumers";
import { api, code } from "./../global";

const headerParams = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin,X-Requested-With,Content-Type,Accept,Authorization",
  "Access-Control-Max-Age": "1728000",
};

export function requestFreeAccessToken(data) {
  return request({
    url:
      api.ssa.pyurl +
      "/" +
      api.ssa.requestFreeAccessToken +
      "/" +
      Buffer.from(JSON.stringify(data)).toString("base64"),
    method: "POST",
    headers: headerParams,
    body: Buffer.from(JSON.stringify(data)).toString("base64"),
  });
}

export function loadOrderBook(data) {
  return request({
    url: api.ssa.url + "/" + api.ssa.loadOrderBook,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadVolatalitiesStrikes(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadVolatalitiesStrikes,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadATMIV(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadATMIV,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadOptionVolumeHeatMap(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadOptionVolumeHeatMap,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function dailyAggregateOptionsTradeVolumes(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.dailyAggregateOptionsTradeVolumes,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function fetchSpotDailyClosingPrices(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.spotDailyClosingPrices,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadSkewVsDeltaOptions(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadSkewVsDeltaOptions,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadVolatalitiesVsStrike(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadVolatalitiesVsStrike,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadStatsStd(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadStatsStd,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadStatsCorrelationMatrix(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadStatsCorrelationMatrix,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadStatsCorrelationSeries(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadStatsCorrelationSeries,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function requestRawData(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.requestRawData,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function registerUser(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.registerUser,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function resetPassword(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.resetPassword,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function saveNewPassword(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.saveNewPassword,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function login(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.login,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadSpotHourlyReturnsHeatMap(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadSpotHourlyReturnsHeatMap,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadSpotVolumeHeatMap(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadSpotVolumeHeatMap,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadOhlc(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadOhlc,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadMultiSeriesOhlc(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadMultiSeriesOhlc,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function tradeData(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.tradeData,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadTopTradedSpotInstruments(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadTopTradedSpotInstruments,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function fetchDailyPortfolioValue(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.fetchDailyPortfolioValue,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function loadVolSurface(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.loadVolSurface,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function sendDataRequestEmail(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.sendDataRequestEmail,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

export function sendGeneralInquiryEmail(data) {
  return request({
    url: api.ssa.url + "/api/" + api.ssa.sendGeneralInquiryEmail,
    method: "POST",
    headers: headerParams,
    body: JSON.stringify(data),
  });
}

const request = (options) => {
  const headers = options.headers;

  return fetch(options.url, {
    headers: headers,
    method: options.method,
    body: options.body,
  })
    .then((response) => {
      return handleStatus(response);
    })
    .then((json) => {
      console.debug("Parsed json string: " + json);
      return json;
    });
};

const handleStatus = (response) => {
  if (response.status === code.SUCCESS || response.ok) {
    var json = response.json();
    json["type"] = "success";
    return json;
  } else {
    throw {
      statusCode: response.status,
      message: response.statusText,
      type: "error",
    };
  }
};

const handleJsonValue = (json) => {
  if (Array.isArray(json.value)) {
    return json;
  } else {
    json.value = [];
    return json;
  }
};
