import React, { useRef, useState } from "react";
import { OptionPricerQuery } from "./option_pricer_query";
import { OptionPricerResultDisplay } from "./option_pricer_result_display";

export const QuantunaOptionPricerInner = (props) => {
  const [optionQuery, setOptionQuery] = useState("");
  return (
    <main>
      <section className="section-pricer">
        {/* Adding the pricer-query element here */}
        <OptionPricerQuery option_query_fn={setOptionQuery} />
        {/* ask for inputs here to build the required grid for pricing calculation */}
        {optionQuery && optionQuery != "" && (
          <OptionPricerResultDisplay option_query={optionQuery} />
        )}
      </section>
    </main>
  );
};
