import React from "react";
import transparent from "../../../assets/images/Transparent.png";
// import logogreen1 from "./logo-green-1x.png";
// import logogreen2 from "./logo-green-2x.png";

export const FooterThin = () => {
  return (
    <footer className="footermin">
      {/* diivide the footer in three parts */}
      {/* make the container to hold all the elements of the footer */}
      <div className="footermin__row">
        {/* <div className="col-1-of-3"> */}
        <div className="footermin__row__navcell">
          <div className="footermin__row__navcell__navigation">
            <ul className="footermin__row__navcell__list">
              <li className="footermin__row__navcell__item">
                <a href="#" className="footermin__row__navcell__link">
                  Company
                </a>
              </li>
              <li className="footermin__row__navcell__item">
                <a href="#" className="footermin__row__navcell__link">
                  Contact Us
                </a>
              </li>
              <li className="footermin__row__navcell__item">
                <a href="#" className="footermin__row__navcell__link">
                  Careers
                </a>
              </li>
              <li className="footermin__row__navcell__item">
                <a href="#" className="footermin__row__navcell__link">
                  Privacy
                </a>
              </li>
              <li className="footermin__row__navcell__item">
                <a href="#" className="footermin__row__navcell__link">
                  Terms
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footermin__row__logocell">
          <div className="footermin__row__logocell__logo-box">
            {/* <picture className="footermin__logo">
          <source
            srcset="./logo-green-1x.png 1x, ./logo-green-2x.png 2x"
            media="(max-width: 37.5rem)"
          />
          <img
            srcset="./logo-green-1x.png 1x, ./logo-green-2x.png 2x"
            alt="Full logo"
            className="footermin__logo"
            src="./logo-green-2x.png"
          />
        </picture> */}
            <img
              className="footermin__row__logocell__logo footermin__row__logocell__logoimg"
              alt="Quantuna"
              src={transparent}
            />
          </div>
        </div>
        <div className="footermin__row__copycell">
          <p className="footermin__row__copycell__copyright">
            <span className="footermin__row__copycell__copyright-text">
              Quantuna data analytics and option pricing company.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      {/* footer contents to go here */}
      <div className="footer__logo-box">
        {/* <picture className="footer__logo">
          <source
            srcset="./logo-green-1x.png 1x, ./logo-green-2x.png 2x"
            media="(max-width: 37.5rem)"
          />
          <img
            srcset="./logo-green-1x.png 1x, ./logo-green-2x.png 2x"
            alt="Full logo"
            className="footer__logo"
            src="./logo-green-2x.png"
          />
        </picture> */}
        <img
          className="logo footer__logoimg"
          alt="Quantuna"
          src={transparent}
        />
      </div>
      <div className="row">
        <div className="col-1-of-2">
          <div className="footer__navigation">
            <ul className="footer__list">
              <li className="footer__item">
                <a href="#" className="footer__link">
                  Company
                </a>
              </li>
              <li className="footer__item">
                <a href="#" className="footer__link">
                  Contact Us
                </a>
              </li>
              <li className="footer__item">
                <a href="#" className="footer__link">
                  Careers
                </a>
              </li>
              <li className="footer__item">
                <a href="#" className="footer__link">
                  Privacy
                </a>
              </li>
              <li className="footer__item">
                <a href="#" className="footer__link">
                  Terms
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-1-of-2">
          <p className="footer__copyright">
            <span className="footer__copyright-text">
              Quantuna data analytics and option pricing company.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
