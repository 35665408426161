import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HC_exporting from 'highcharts/modules/exporting'
import { useLoadSkewVsDeltaOptionsQuery } from '../../../api/OptionsApi';

export const SkewDelta = (props) => {
    const {data, error, isLoading} = useLoadSkewVsDeltaOptionsQuery(props.baseCurrency);

    HighchartsHeatmap(Highcharts);
    HC_exporting(Highcharts);

    const getOption = () => {
          return {
            title: {
                  text: 'Skew Delta - ' + props.baseCurrency
                },

                subtitle: {
                  text: 'Source: Quantuna Analytics'
                },

                yAxis: {
                  title: {
                    text: 'IV'
                  }
                },

                legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle'
                },

                plotOptions: {
                  series: {
                    label: {
                      connectorAllowed: false
                    },
                    pointStart: 2010
                  }
                },
          chart: {
              zoomType: 'x'
            },
              xAxis: {
                  title: {
                      text: 'Delta'
                  }
              },
              series: [{
                  name: props.baseCurrency + ' IV',
                  data: data && data.data
              }],

                responsive: {
                  rules: [{
                    condition: {
                      maxWidth: 500
                    },
                    chartOptions: {
                      legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                      }
                    }
                  }]
                }
          };
      };

      if(isLoading){
        return (
          <div>Loading ...</div>
        );
      } else {
        return (
          <div>
              <HighchartsReact highcharts={Highcharts} immutable={true} options={getOption()} />
          </div>
      );}
}