import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HC_exporting from 'highcharts/modules/exporting'
import { useLoadDailyAggregateOptionsTradeVolumesQuery } from '../../../api/OptionsApi';

export const OptionsAggregateDailyVolume = (props) => {
    const {data, error, isLoading} = useLoadDailyAggregateOptionsTradeVolumesQuery(props.baseCurrency);

    HighchartsHeatmap(Highcharts);
    HC_exporting(Highcharts);

    const getOption = () => {
          return {
              chart: {
                type: 'column'
              },
              title: {
                text: props.baseCurrency + ' Daily Average Volume'
              },
              xAxis: {
                type: 'datetime',
                dateFormat: 'yyyy-mm-dd',
                title: {
                    text: 'Date'
                }
              },
              yAxis: {
                min: 0,
                title: {
                  text: 'Option Volume'
                },
              },
              legend: {
                align: 'right',
                x: -30,
                verticalAlign: 'top',
                y: 25,
                floating: true,
                backgroundColor:
                  Highcharts.defaultOptions.legend.backgroundColor || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false
              },
              tooltip: {

                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
              },
              plotOptions: {
                heatmap: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: true,
                    formatter: function() {
                      return Highcharts.numberFormat( this.y/1000000, 1) + "M";
                    }
                  }
                }
              },
              series: [{
                type: 'column',
                name: 'Deribit',
                    data: data && data.map((d) => ([d.time, d.value])),
              }]
          };
      };

    if(isLoading){
      return (
        <div>Loading ...</div>
      );
    } else {
      return (
        <div>
            <HighchartsReact highcharts={Highcharts} immutable={true} options={getOption()} />
        </div>
    );}
}