// this file will contain code for landing of the dashboard page
// show a list of all public and private dashboards
// provide functionality for the user to  build a new dashboard and be able to share it with a group or publically
import React from "react";
import QuantunaHeader from "../../layouts/layout-components/header/QuantunaHeader";
import Footer, {
  FooterThin,
} from "../../layouts/layout-components/footer/Footer";
import { useEnrichOptionCoinsCoveredQuery } from "../../api/InstrumentsApi";
import { Spinner } from "reactstrap";
import { QuantunaSingleOptionPrices_Inner } from "./components/single_option_pricer_comps";

const QuantunaSingleOptionPrices = () => {
  const coins = ["BTC", "ETH"];

  // lets call a server method and enrich the coins with images
  const enrichedCoins = useEnrichOptionCoinsCoveredQuery(coins);
  console.log("Received enriched coins from service", enrichedCoins);
  const coinData =
    enrichedCoins && enrichedCoins.isSuccess && enrichedCoins.data
      ? enrichedCoins.data
      : undefined;

  return (
    <>
      <QuantunaHeader />
      <main>
        {/* This page has two sections  */}
        {/* Section (1): Used for pricing all the options */}
        {<QuantunaSingleOptionPrices_Inner coinData={coinData} />}
      </main>
      <FooterThin />
    </>
  );
};

export default QuantunaSingleOptionPrices;
