import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../global";

export const allInstrumentsApi = createApi({
  reducerPath: "allInstrumentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.ssa.url + "/api/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState() && getState().auth && getState().auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      // headers.set('content-type', 'application/json')

      return headers;
    },
  }),
  endpoints: (builder) => ({
    enrichOptionCoinsCovered: builder.query({
      query: (currencies) => ({
        url: `enrichOptionCoinsCovered`,
        method: "POST",
        body: {
          currencies: currencies,
          "content-type": "application/json",
        },
      }),
    }),
    getTradedAsset: builder.query({
      query: (currencies) => ({
        url: `getTradedAsset`,
        method: "POST",
        body: {
          currencies: currencies,
          "content-type": "application/json",
        },
      }),
    }),
    getAsset: builder.query({
      query: (currencies) => ({
        url: `getAsset`,
        method: "POST",
        body: {
          currencies: currencies,
          "content-type": "application/json",
        },
      }),
    }),
    getCoveredAssets: builder.query({
      query: () => ({
        url: `getAssetsCovered`,
        method: "POST",
        body: {
          "content-type": "application/json",
        },
      }),
    }),
    getAllSpotTradeOhlcvData: builder.query({
      query: () => ({
        url: `getAllSpotTradeOhlcvData`,
        method: "POST",
        body: {
          // getTestData: "xxx",
          "content-type": "application/json",
        },
      }),
    }),
    getAllCoinsCovered: builder.query({
      query: () => ({
        url: `getAllCoinsCovered`,
        method: "POST",
        body: {
          // getTestData: "xxx",
          "content-type": "application/json",
        },
      }),
    }),
    getLandingPageSpotAssets: builder.query({
      query: (category) => ({
        url: `getLandingPageSpotAssets`,
        method: "POST",
        body: {
          category: category,
          "content-type": "application/json",
        },
      }),
    }),
    getDailyAssetPrices: builder.query({
      query: (currency) => ({
        url: `getDailyAssetPrices`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadSpotVolumeHeatMap: builder.query({
      query: (currency) => ({
        url: `loadSpotVolumeHeatMap`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadSpotDailyClosingPrices: builder.query({
      query: (currency) => ({
        url: `spotDailyClosingPrices`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadSpotHourlyReturnsHeatMap: builder.query({
      query: (currency) => ({
        url: `loadSpotHourlyReturnsHeatMap`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadStatsStd: builder.query({
      query: (currency) => ({
        url: `loadStatsStd`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadStatsCorrelationMatrix: builder.query({
      query: (currency) => ({
        url: `loadStatsCorrelationMatrix`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadStatsCorrelationSeries: builder.query({
      query: (payload) => ({
        url: `loadStatsCorrelationSeries`,
        method: "POST",
        body: {
          currency: payload.currency,
          baseCurrency: payload.baseCurrency,
          "content-type": "application/json",
        },
      }),
    }),
    /*
		updatePokemon: builder.mutation({
			query: ({ name, patch }) => ({
				url: `pokemon/${name}`,
				method: 'PATCH',
				body: patch, // fetchBaseQuery automatically adds `content-type: application/json` to the Headers and calls `JSON.stringify(patch)`
			}),
		}),
		*/
  }),
});

export const {
  useGetCoveredAssetsQuery,
  useGetAllSpotTradeOhlcvDataQuery,
  useGetAllCoinsCoveredQuery,
  useGetDailyAssetPricesQuery,
  useLoadSpotVolumeHeatMapQuery,
  useGetAssetQuery,
  useGetTradedAssetQuery,
  useLoadStatsStdQuery,
  useLoadStatsCorrelationSeriesQuery,
  useLoadSpotHourlyReturnsHeatMapQuery,
  useLoadSpotDailyClosingPricesQuery,
  useGetLandingPageSpotAssetsQuery,
  useLoadStatsCorrelationMatrixQuery,
  useEnrichOptionCoinsCoveredQuery,
} = allInstrumentsApi;
