// this file will contain code for landing of the dashboard page
// show a list of all public and private dashboards
// provide functionality for the user to  build a new dashboard and be able to share it with a group or publically
import React, { useRef, useState } from "react";
import { RealtimeCryptoPrice } from "../../dashboard/components/basic/RealtimeCryptoPriceComponent";

// 3d charting - https://github.com/jamesleesaunders/d3-x3d#examples

export const OptionPricerQuery = (props) => {
  // an input box to start with to help price the options
  const [queryPositionState, setQueryPositionState] = useState(
    "pricer-query-container__center"
  );
  const [queryHelpInvisible, setQueryHelpInvisible] = useState("");
  const [queryShortHelpInvisible, setQueryShortHelpInvisible] =
    useState("invisible");

  const optionQueryRef = useRef();

  const processOptionPricingQuey = (event) => {
    if (event.keyCode == 13) {
      console.info(
        "Checking if enter key is hit - " +
          event.key +
          " - " +
          event.keyCode +
          " - " +
          event.target.value +
          " - " +
          optionQueryRef.current.value
      );

      setQueryPositionState("pricer-query-container__notcenter");
      setQueryHelpInvisible("invisible");
      setQueryShortHelpInvisible("pricer-query-container__help_row");

      // lets call the option pricer on the backend now
      // we must validate the option query before setting it for pricing
      props.option_query_fn(event.target.value);
    } else if (event.keyCode == 27) {
      event.preventDefault();
      // if this is esc kep press then reset the query section
      console.info(
        "Checking if enter key is hit - " +
          event.key +
          " - " +
          event.keyCode +
          " - " +
          event.target.value +
          " - " +
          optionQueryRef.current.value
      );

      setQueryPositionState("pricer-query-container__center");
      setQueryHelpInvisible("");
      setQueryShortHelpInvisible("invisible");
    }
  };
  return (
    <>
      <div className={`pricer-query-container ${queryPositionState}`}>
        {/* lets make the grid to show the help contents that will be made invisible on-load of data */}
        <div className={`pricer-query-container__help ${queryHelpInvisible}`}>
          <div className="pricer-query-container__help_row">
            <div>
              <span className="pricer-query-container__help_row__hglght">
                ASSET
              </span>
              -STRIKE-DELTA-MATURITY-N
            </div>
            <div>BTC or ETH</div>
            <div>Asset name to price the option</div>
          </div>
          <div className="pricer-query-container__help_row">
            <div>
              ASSET-
              <span className="pricer-query-container__help_row__hglght">
                STRIKE
              </span>
              -DELTA-MATURITY-N
            </div>
            <div>50000</div>
            <div>Target strike price</div>
          </div>
          <div className="pricer-query-container__help_row">
            <div>
              ASSET-STRIKE-
              <span className="pricer-query-container__help_row__hglght">
                DELTA
              </span>
              -MATURITY-N
            </div>
            <div>500</div>
            <div>number used to distance options</div>
          </div>
          <div className="pricer-query-container__help_row">
            <div>
              ASSET-STRIKE-DELTA-
              <span className="pricer-query-container__help_row__hglght">
                MATURITY
              </span>
              -N
            </div>
            <div>90</div>
            <div>Days to maturity</div>
          </div>
          <div className="pricer-query-container__help_row">
            <div>
              ASSET-STRIKE-DELTA-MATURITY-
              <span className="pricer-query-container__help_row__hglght">
                N
              </span>
            </div>
            <div>5</div>
            <div>number of call/put options [1-20] (optional, default 20)</div>
          </div>
          <div className="pricer-query-container__help_row">
            <div>&nbsp;</div>
            <div className="pricer-query-container__help_row__hglght">
              BTC-50000-500-90
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div className={`${queryShortHelpInvisible}`}>
          <div>&nbsp;</div>
          <div className="pricer-query-container__help_row__hglght">
            <span className="pricer-query-container__help_row__hglght_short">
              ASSET-STRIKE-DELTA-MATURITY-N
            </span>
          </div>
          <div>&nbsp;</div>
        </div>
        <div className="pricer-query-container__help_row">
          {/* an input box to capture details to price options */}
          <div>&nbsp;</div>
          <input
            type="text"
            id="optionQuery"
            name="optionQuery"
            ref={optionQueryRef}
            className="pricer-query-container__input-box"
            placeholder="ASSET-STRIKE-DELTA-MATURITY"
            onKeyUp={processOptionPricingQuey}
          />
          <div>&nbsp;</div>
        </div>
      </div>
    </>
  );
};

const QuantunaOptionPricerInner_BKP = (props) => {
  return (
    <>
      <div className="section-pricer__container">
        {/* this is the grid container for the pricing */}
        <div className="section-pricer__container__row">
          <div className="section-pricer__container__row__col required">
            Asset:
          </div>
          <div className="section-pricer__container__row__col">Spot</div>
          <div className="section-pricer__container__row__col optionally-required">
            Strike
          </div>
          <div className="section-pricer__container__row__col optionally-required">
            Delta
          </div>
          <div className="section-pricer__container__row__col">+n-m</div>
          <div className="section-pricer__container__row__col">Button(GEN)</div>
        </div>
        <div className="section-pricer__container__row section-pricer__container__row--header">
          {/* this is a single row in the pricer container */}
          <div className="section-pricer__container__row__col">
            {/* these are various columns in each of the row */}
            {/* these elements will hold the actual data for the option calculator */}
            Asset
          </div>
          <div className="section-pricer__container__row__col">Spot Price</div>
          <div className="section-pricer__container__row__col">
            Strike Price
          </div>
          <div className="section-pricer__container__row__col">Maturity</div>
          <div className="section-pricer__container__row__col">P/C</div>
          <div className="section-pricer__container__row__col">Price</div>
        </div>

        <div className="section-pricer__container__row section-pricer__container__row">
          {/* this is a single row in the pricer container */}
          <div className="section-pricer__container__row__col">
            {/* these are various columns in each of the row */}
            {/* these elements will hold the actual data for the option calculator */}
            z
          </div>
          <div className="section-pricer__container__row__col">z</div>
          <div className="section-pricer__container__row__col">z</div>
          <div className="section-pricer__container__row__col">z</div>
          <div className="section-pricer__container__row__col">z</div>
          <div className="section-pricer__container__row__col">z</div>
        </div>
      </div>
    </>
  );
};
