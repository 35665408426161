import React from "react";
import "./Spinner.scss";
const Spinner1 = () => {
  return (
    <div className="fallback-spinner">
      <div className="loading component-loader">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export const Spinner = () => {
  return (
    <div className="fallback-spinner">
      <img src="../../assets/images/Transparent.png"></img>
      <div className="spinner-loader"></div>
    </div>
  );
};

export default Spinner;
