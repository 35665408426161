import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import thunk from "redux-thunk";
import { useDispatch } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import Reducers from "./Reducers";
import { allInstrumentsApi } from "../api/InstrumentsApi";
import { allOptionsApi } from "../api/OptionsApi";
import { allVolSurfaceApi } from "../api/VolSurfaceApi";
import { allSettingsApi } from "../api/SettingsApi";
import { allProductOfferingsApi } from "../api/ProductOfferingsApi";
import settingsReducer from "./settings/Reducer";
import AppNavStateReducer from "./settings/AppNavState";
import { isProdServer } from "../global";
import { allDataProductApi } from "../api/DataProductApi";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    ["settings"]: settingsReducer,
    ["appNavState"]: AppNavStateReducer,
    [allInstrumentsApi.reducerPath]: allInstrumentsApi.reducer,
    [allOptionsApi.reducerPath]: allOptionsApi.reducer,
    [allVolSurfaceApi.reducerPath]: allVolSurfaceApi.reducer,
    [allSettingsApi.reducerPath]: allSettingsApi.reducer,
    [allProductOfferingsApi.reducerPath]: allProductOfferingsApi.reducer,
    [allDataProductApi.reducerPath]: allDataProductApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      allInstrumentsApi.middleware,
      allOptionsApi.middleware,
      allVolSurfaceApi.middleware,
      allSettingsApi.middleware,
      allProductOfferingsApi.middleware,
      allDataProductApi.middleware
      // logger
    ),
  devTools: !isProdServer,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization

// export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch(); // Export a hook that can be reused to resolve types

export default store;

setupListeners(store.dispatch);
