import React, { useState, useRef } from "react";
import { CountryDropdown } from "react-country-region-selector";
import "react-phone-number-input/style.css";
import styles from "./ContactUs.module.css";
import { sendGeneralInquiryEmail } from "../../api/ssa";
import OperationCompletionMessage from "../components/core/OperationCompletionMessage";
import QuantunaHeader from "../../layouts/layout-components/header/QuantunaHeader";
import Footer from "../../layouts/layout-components/footer/Footer";

const ContactUs = (props) => {
  // set requesting token to null so that if the pop-up is open it goes away

  const [err, setErr] = useState("");

  const [onOperationCompletionMsg, setOnOperationCompletionMsg] = useState("");

  const [country, selectCountry] = useState("US");

  const nameRef = useRef();
  const emailRef = useRef();
  const customMessageText = useRef();

  const SendGeneralRequest = (event) => {
    event.preventDefault();
    // ensure that all the required fields are provided value
    console.info("Raising customer sales request for quantuna sales team.");
    console.info(nameRef.current.value);
    console.info(emailRef.current.value);
    console.info(country);
    console.info(customMessageText.current.value);
    console.info("Raising customer sales request for quantuna sales team.");
    if (
      nameRef.current.value == "" ||
      emailRef.current.value == "" ||
      country == "" ||
      customMessageText.current.value == ""
    ) {
      setErr(
        "Must input all the required fields for sales team to connect with you"
      );
      return;
    } else {
      setErr("");
    }

    // send data to the backend to send the email
    // query: (full_name, email, org, country, phone, message, subject)
    const subject =
      "GeneralRequest From: [Name: " + nameRef.current.value + "]";
    console.info("Sending the email now - " + subject);

    const payload = {
      full_name: nameRef.current.value,
      email: emailRef.current.value,
      country: country,
      message: customMessageText.current.value,
      subject: subject,
    };

    sendGeneralInquiryEmail(payload).then((response) => {
      // setResponseMessage(response); //TODO: NEED TO DO THIS
      console.info("response =======> ", response);
      if (response == "Success") {
        // props.set_ondisplaycomp("oper-comp-msg");
        setOnOperationCompletionMsg(
          "Thanks for sending an inquiry to Quantuna Support. Someone will get back to you soon."
        );
        console.info("Data request sent with the payload - " + payload);
      } else {
        // setResponseMessage("There was a problem. Please try again later");
        // setShowFailure(true);
      }
    });
    // Set the status of the pop-up appropriately so that the user knows the mail is sent

    return;
  };

  const performeOnClosingCompletionMessage = () => {
    // we will reset the state variable values here so that the page goes back to original state
    setOnOperationCompletionMsg("");
  };

  return (
    <>
      <QuantunaHeader />
      <main>
        <section className="section-contactus">
          {/* if the operation is complete, lets show the operation completion message and take the user out of here */}
          {onOperationCompletionMsg && (
            <OperationCompletionMessage
              msg={onOperationCompletionMsg}
              close_completion_messge={performeOnClosingCompletionMessage}
            />
          )}
          {!onOperationCompletionMsg && (
            <div className="section-contactus__getnow">
              <div className="section-contactus__getnow-details">
                <div className="section-contactus__gtd--itemstacked">
                  <div className="section-contactus__gtd section-contactus__gtd--topcaption">
                    Compose Message
                  </div>
                  <div>Fill in the following details and send us a message</div>
                  <div className="section-contactus__offeringError">{err}</div>
                </div>
                <div className="section-contactus__gtd section-contactus__gtd--item">
                  <div className="section-contactus__gtd--itemgrouped">
                    {/* ask the customer to input some personal information so that we can get in touch with them */}
                    <div className="section-contactus__gtd--captions required">
                      Full Name
                    </div>
                    <div>
                      <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        ref={nameRef}
                        className="section-contactus__offering-input-box"
                        placeholder="FName LName"
                      />
                    </div>
                  </div>

                  <div className="section-contactus__gtd--itemgrouped">
                    {/* ask the customer to input some personal information so that we can get in touch with them */}
                    <div className="section-contactus__gtd--captions required">
                      Email
                    </div>
                    <div>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        ref={emailRef}
                        className="section-contactus__offering-input-box"
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="section-contactus__gtd--itemgrouped">
                    {/* ask the customer to input some personal information so that we can get in touch with them */}
                    <div className="section-contactus__gtd--captions required">
                      Country
                    </div>
                    <div>
                      <CountryDropdown
                        value={country}
                        valueType="short"
                        // onChange={(val) => selectCountry(val)}
                        priorityOptions={["US", "CA", "GB"]}
                        // whitelist={["US", "CA", "GB"]}
                        defaultOptionLabel={"Country"}
                        className="section-contactus__offering-select-box"
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
                <div className="section-contactus__gtd section-contactus__gtd--item required">
                  <textarea
                    className="section-contactus__send-email-textbox required"
                    id="custom-message"
                    name="custom-message"
                    rows="10"
                    column="100"
                    ref={customMessageText}
                  ></textarea>
                </div>
                {/* <div className="gtd gtd--item gtd--finalorderdetails">
          {onOrderDetails}
        </div> */}
                <button
                  key={"submit-email-message"}
                  className={`${styles["quantuna-email-submit-button"]}`}
                  onClick={SendGeneralRequest}
                >
                  send
                </button>
              </div>
            </div>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ContactUs;
