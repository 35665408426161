import React from "react";
// import "react-data-grid/lib/styles.css";
// import DataGrid from "react-data-grid";
import Spinner from "../../spinner/Spinner";
// import { Data } from "react-data-grid-addons";

const dateFormatter = new Intl.DateTimeFormat(navigator.language);
function TimestampFormatter({ timestamp }) {
  return <>{dateFormatter.format(timestamp)}</>;
}

const getColumns = () => {
  return [
    {
      key: "underlyingIndex",
      name: "Underlying Index",
      width: "max-content",
      headerCellClass: "header-cell-class",
      frozen: true,
    },
    {
      key: "timestamp",
      name: "Timestamp",
      formatter(props) {
        return <TimestampFormatter timestamp={props.row.timestamp} />;
      },
      headerCellClass: "header-cell-class",
    },
    {
      key: "volumeStats",
      name: "Volume Stats",
      headerCellClass: "header-cell-class",
    },
    {
      key: "priceChangeStats",
      name: "Price Change Stats",
      headerCellClass: "header-cell-class",
    },
    {
      key: "lowStats",
      name: "Low Stats",
      headerCellClass: "header-cell-class",
    },
    {
      key: "highStats",
      name: "High Stats",
      headerCellClass: "header-cell-class",
    },
    {
      key: "state",
      name: "State",
      headerCellClass: "header-cell-class",
    },
    {
      key: "settlementPrice",
      name: "Settlement Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "openInterest",
      name: "Open Interest",
      headerCellClass: "header-cell-class",
    },
    {
      key: "minPrice",
      name: "Min Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "maxPrice",
      name: "Max Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "markPrice",
      name: "Mark Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "markIv",
      name: "MarkIV",
      headerCellClass: "header-cell-class",
    },
    {
      key: "lastPrice",
      name: "Last Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "instrumentName",
      name: "Instrument Name",
      headerCellClass: "header-cell-class",
    },
    {
      key: "indexPrice",
      name: "Index Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "bidIv",
      name: "BidIV",
      headerCellClass: "header-cell-class",
    },
    {
      key: "bestBidPrice",
      name: "Best Bid Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "bestBidAmount",
      name: "Best Bid Amount",
      headerCellClass: "header-cell-class",
    },
    {
      key: "bestAskPrice",
      name: "Best Ask Price",
      headerCellClass: "header-cell-class",
    },
    {
      key: "bestAskAmount",
      name: "Best Ask Amount",
      headerCellClass: "header-cell-class",
    },
    {
      key: "askIv",
      name: "AskIV",
      headerCellClass: "header-cell-class",
    },
    {
      key: "vega",
      name: "VEGA",
      headerCellClass: "header-cell-class",
    },
    {
      key: "theta",
      name: "THETA",
      headerCellClass: "header-cell-class",
    },
    {
      key: "rho",
      name: "RHO",
      headerCellClass: "header-cell-class",
    },
    {
      key: "gamma",
      name: "GAMMA",
      headerCellClass: "header-cell-class",
    },
    {
      key: "delta",
      name: "DELTA",
      headerCellClass: "header-cell-class",
    },
    {
      key: "underlying",
      name: "Underlying",
      headerCellClass: "header-cell-class",
    },
    {
      key: "option_expiry",
      name: "Option Expiry",
      headerCellClass: "header-cell-class",
    },
    {
      key: "option_type",
      name: "Option Type",
      headerCellClass: "header-cell-class",
    },
    {
      key: "strike",
      name: "Strike",
      headerCellClass: "header-cell-class",
    },
    {
      key: "yyyymmdd",
      name: "YYYYMMDD",
      headerCellClass: "header-cell-class",
    },
    {
      key: "days_to_expiry",
      name: "Days To Expiry",
      headerCellClass: "header-cell-class",
    },
  ];
};

export const OptionsOrdersTable = (props) => {
  const data = props.chart_data;

  console.log("YYYYYYYYYYYY =============> ", props.container_width, data);
  const table_max_width = props.container_width
    ? props.container_width
    : "550px";

  const columns = getColumns();

  const columns1 = [
    { key: "id", name: "ID" },
    { key: "title", name: "Title" },
  ];

  const rows = [
    { id: 0, title: "Example" },
    { id: 1, title: "Demo" },
  ];

  //   #6d7eec
  // #902fbf
  // #d13fcd
  // #9959e5
  // #fbb17d
  // #14d1fb
  // #4e65f4
  // #5693f4
  // #439ef4
  // "#5693f4",
  const tableCustomStyles = {
    headCells: {
      style: {
        fontWeight: "300",
        fontSize: "2rem",
        // fontSize: "1.6em",
        // fontWeight: "bold",
        // justifyContent: "center",
        backgroundColor: "#0047AB",
        color: "white",
        borderBottomWidth: "2px",
        borderBottomColor: "#902fbf",
        borderBottomStyle: "solid",
      },
    },
  };

  return (
    <div
      className="quantuna-table-data-display"
      style={{
        overflow: "auto",
        maxHeight: "400px",
        overflowX: "auto",
        maxWidth: { table_max_width },
      }}
    >
      {!data && <Spinner />}
      {data && (
        <>
          {/* <DataGrid
            className="rdg-light"
            columns={columns}
            rows={data}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
          /> */}
        </>
      )}
    </div>
  );

  //   return (
  //     <div
  //       className="quantuna-table-data-display"
  //       style={{ overflow: "auto", maxHeight: "400px", overflowX: "auto" }}
  //     >
  //       {!data && <Spinner />}
  //       {data && (
  //         <DataTable
  //           columns={columns}
  //           data={data}
  //           customStyles={tableCustomStyles}
  //           fixedHeader={true}
  //           responsive={true}
  //         />
  //       )}
  //     </div>
  //   );
};

export default OptionsOrdersTable;
