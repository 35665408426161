import React, { useState, useEffect } from "react";
import { api } from "../../../global";
import axios from "axios";
import { yyyymmdd_today } from "../../../util/UtilFunctions";

export const AllStraddleAndStragleTable = (props) => {
  // get the strangle and straddle data from the props
  console.log("data from the props", props.data_key, props.data);
  const data_key = props.data_key;
  const data = props.data && data_key in props.data && props.data[data_key];

  return (
    <div className="straddle-and-strangle--table">
      {/* put a title on top of the table to take care of the straddle/strangle details */}
      <div className="straddle-and-strangle--table--row straddle-and-strangle--table--row--sastitleheader">
        <div
          className="straddle-and-strangle--table--row--cell"
          key={"straddle-and-strangle--table--row--cell longstraddle"}
        >
          <span className="sastitleheadercell">
            {props.title1} {props.title2}
          </span>
        </div>
      </div>
      {/* each table will be made up rows and rows will be made up of cells */}
      <div className="straddle-and-strangle--table--row straddle-and-strangle--table--row--sasheader">
        {[
          "SrNo",
          "Exp Date",
          "underlying_price",
          "Leg1 Strike",
          "Leg1 Bid",
          "Leg1 Ask",
          "Leg1 Delta",
          "Leg2 Strike",
          "Leg2 Bid",
          "Leg2 Ask",
          "Leg2 Delta",
          "Avg IV",
          "Net Credit",
          "% of Stock",
          "BE-",
          "BE+",
          "Strangle/Straddle",
          "Long/Short",
        ].map((a) => (
          <div
            className="straddle-and-strangle--table--row--cell straddle-and-strangle--table--row--sasdata"
            key={"straddle-and-strangle--table--row--cell" + a}
          >
            {a}
          </div>
        ))}
      </div>
      {/* show an empty row if there is no data loaded yet */}
      {!data && (
        <div
          className="straddle-and-strangle--table--row straddle-and-strangle--table--row--sasdata"
          key={"sasdata"}
        >
          {[
            "SrNo",
            "Exp Date",
            "underlying_price",
            "Leg1 Strike",
            "Leg1 Bid",
            "Leg1 Ask",
            "Leg1 Delta",
            "Leg2 Strike",
            "Leg2 Bid",
            "Leg2 Ask",
            "Leg2 Delta",
            "Avg IV",
            "Net Credit",
            "% of Stock",
            "BE-",
            "BE+",
            "Strangle/Straddle",
            "Long/Short",
          ].map((a) => (
            <div
              className="straddle-and-strangle--table--row--cell"
              key={"straddle-and-strangle--table--row--cell--contents" + a}
            >
              ---
            </div>
          ))}
        </div>
      )}

      {/* if the data is found, then show the data */}
      {data &&
        data.map((recrd, idx) => (
          <div
            className="straddle-and-strangle--table--row straddle-and-strangle--table--row--sasdata"
            key={"sasdata" + idx}
          >
            {[
              "SrNo",
              "exp_date",
              "underlying_price",
              "leg1_strike",
              "leg1_bid",
              "leg1_ask",
              "leg1_delta",
              "leg2_strike",
              "leg2_bid",
              "leg2_ask",
              "leg2_delta",
              "avg_iv",
              "net_credit",
              "pct_of_stock",
              "be_minus",
              "be_plus",
              "strangle_or_straddle",
              "long_or_short",
            ].map((dk) => (
              <div
                className="straddle-and-strangle--table--row--cell"
                key={
                  "straddle-and-strangle--table--row--cell--contents" + dk + idx
                }
              >
                {dk === "SrNo" && idx}
                {dk !== "SrNo" && recrd[dk]}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export const StraddleAndStrangle = (props) => {
  const sas_title = props.sas_title;

  // all strangle and straddle data array
  const [allStrangleAndStraddle, setAllStrangleAndStraddle] = useState(null);
  //   const [selectedCoin, setSelectedCoin] = useState(props.underlying);

  // lets request all the straddle and strangle data from the server
  // construct the url
  var url = api.ssa.url + "/" + api.ssa.getAllStraddleAndStrangleData;
  const today_str = yyyymmdd_today();
  console.log(
    "Gettig all strangle and straddle screener data from server now - ",
    url,
    props.underlying,
    today_str
  );

  function FetAllStraddleAndStrangleData() {
    axios
      .post(
        url,
        {
          underlying: props.underlying,
          calculation_date: today_str,
          apikey:
            localStorage.getItem("apikey") == null
              ? "GLOBAL"
              : localStorage.getItem("apikey"),
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        // retrieve data from response
        console.log(
          "Printing the response from server for coin - " + props.underlying,
          response
        );
        return response.data;
      })
      .then((data) => {
        console.log(
          "Setting the data now for coin - " + props.underlying,
          data
        );
        data && setAllStrangleAndStraddle(data);
      })
      .catch((error) => {
        console.error(
          "Error happened while getting the strangle and straddle data",
          error
        );
      });
  }

  // useEffect to load the data on component load
  useEffect(() => {
    FetAllStraddleAndStrangleData();
  }, [props.underlying]);

  if (!allStrangleAndStraddle) return "data loading ...";
  return (
    <>
      <div className="straddle-and-strangle-container">
        {/* lets define a flex container going in the row direction */}
        {/* the container will have two main elements (1) title of the table (2) table itself */}
        <div
          className="straddle-and-strangle--title"
          key={"straddle-and-strangle-container-title" + sas_title}
        >
          {sas_title}
        </div>
        {allStrangleAndStraddle && (
          <AllStraddleAndStragleTable
            title1={"Straddle And Strangle"}
            title2={"(" + props.underlying + ")"}
            data_key={"AllData"}
            data={allStrangleAndStraddle}
          />
        )}
      </div>
    </>
  );
};
