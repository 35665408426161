import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HC_exporting from 'highcharts/modules/exporting'
import { useLoadOptionVolumeHeatMapQuery } from '../../../api/OptionsApi';

export const OptionVolumeHourlyDistribution = (props) => {
    const {data, error, isLoading} = useLoadOptionVolumeHeatMapQuery(props.baseCurrency);

    function getPointCategoryName(point, dimension) {
        var series = point.series,
            isY = dimension === 'y',
            axis = series[isY ? 'yAxis' : 'xAxis'];
        return axis.categories[point[isY ? 'y' : 'x']];
    }

    HighchartsHeatmap(Highcharts);
    HC_exporting(Highcharts);

    const getOption = () => {
        return {
          chart: {
              type: 'heatmap',
              marginTop: 40,
              marginBottom: 80,
              plotBorderWidth: 1
            },


            title: {
              text: props.baseCurrency + ' Option Hourly average volume last 30d'
            },

            xAxis: {
              categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
            },

            yAxis: {
              categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              title: null,
              reversed: true
            },

            accessibility: {
              point: {
                descriptionFormatter: function (point) {
                  var ix = point.index + 1,
                    xName = '',
                    yName = '',
                    val = point.value;
                  return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
                }
              }
            },

            colorAxis: {
              min: 0,
              minColor: '#FFFFFF',
              maxColor: Highcharts.getOptions().colors[0]
            },

            legend: {
              align: 'right',
              layout: 'vertical',
              margin: 0,
              verticalAlign: 'top',
              y: 25,
              symbolHeight: 280
            },

            tooltip: {
              formatter: function () {
                    return 'Average Hourly volume for <b>' + props.baseCurrency +'</b> options on <br> ' + 'Day of month: ' + '<b>' + getPointCategoryName(this.point, 'x') + '</b>  <br> Day of week: <b> ' +
                    getPointCategoryName(this.point, 'y') + '</b> is <br><b>' + this.point.value + ' ' + props.baseCurrency + '</b>' ;
              }
            },

            series: [{
              name: 'Option hourly distribution',
              borderWidth: 1,
              data: data && data,
              dataLabels: {
                enabled: true,
                color: '#000000'
              }
            }],

            responsive: {
              rules: [{
                condition: {
                  maxWidth: 500
                },
                chartOptions: {
                  yAxis: {
                    labels: {
                      formatter: function () {
                        return this.value.charAt(0);
                      }
                    }
                  }
                }
              }]
            }

        };
      };

    if(isLoading){
      return (
        <div>Loading ...</div>
      );
    } else {
      return (
        <div>
            <HighchartsReact highcharts={Highcharts} immutable={true} options={getOption()} />
        </div>
    );}
}