import React from "react";

import { VolSurface } from "./VolSurface";
import {
  VolatalitesAgainstStrike,
  VolatalitesAgainstStrikeWrapper,
  VolatalitesAgainstStrikeV2,
} from "./options/VolatalitesAgainstStrike";
import { OptionVolumeHourlyDistribution } from "./OptionVolumeHourlyDistribution";
import { OptionsAggregateDailyVolume } from "./OptionsAggregateDailyVolume";

import { SkewDelta } from "./SkewDelta";
import { UnderlyingAggregateOpenInterest } from "./options/UnderlyingAggregateOpenInterest";
import { DailyUnderlyingAggregateOpenInterest } from "./options/DailyUnderlyingAggregateOpenInterestByStrike";
import { DailyUnderlyingPCRatioOI } from "./options/DailyUnderlyingPCRatioOI";
import { DailyUnderlyingAggregateOpenInterestByExpiry } from "./options/DailyUnderlyingAggregateOpenInterestByExpiry";
import { DailyUnderlyingOpenInterestDelta } from "./options/DailyUnderlyingOpenInterestDelta";
import { UnderlyingVolumeAggregatedByTradeDate } from "./options/UnderlyingVolumeAggregatedByTradeDate";
import { UnderlyingVolumeAggregatedByInstrument } from "./options/UnderlyingVolumeAggregatedByInstrument";
import { UnderlyingVolumeAggregatedByPutCallBuySell } from "./options/UnderlyingVolumeAggregatedByPutCallBuySell";

// use the props.display_type flag to find out the display type
// for table, display the data in table format
// for chart, display the data in chart format
// => convert each of the chart-factories into one more level of factory for
// table or chart
export const ChartsFactory = (props) => {
  const template = props.chart_attrs.template;
  const instrument = props.instrument;
  const chart_attrs = props.chart_attrs;
  const category = chart_attrs.category;

  let dt = props.display_type;
  if (!dt) {
    dt = "chart";
  }

  /* spot */
  if (template == "VolatalitesAgainstStrike") {
    /* option */
    return (
      <VolatalitesAgainstStrike
        baseCurrency={instrument.base}
        chart_additional_attributes={props.chart_additional_attributes}
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
      />
    );
  } else if (template == "VolSurface") {
    console.info(
      "Loading vol surface chart",
      template,
      instrument,
      instrument.base
    );
    return (
      <VolSurface
        baseCurrency={instrument.base}
        chart_additional_attributes={props.chart_additional_attributes}
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
      />
    );
  } else if (template == "OptionsAggregateDailyVolume") {
    return (
      <OptionsAggregateDailyVolume
        baseCurrency={instrument.base}
        chart_additional_attributes={props.chart_additional_attributes}
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
      />
    );
  } else if (template == "OptionVolumeHourlyDistribution") {
    return (
      <OptionVolumeHourlyDistribution
        baseCurrency={instrument.base}
        chart_additional_attributes={props.chart_additional_attributes}
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
      />
    );
  } else if (template == "SkewDelta") {
    return (
      <SkewDelta
        baseCurrency={instrument.base}
        chart_additional_attributes={props.chart_additional_attributes}
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
      />
    );
  } else if (template == "VolatalitesAgainstStrikeWrapper") {
    console.info(
      "Returning the wrapper template - VolatalitesAgainstStrikeWrapper"
    );
    return (
      <VolatalitesAgainstStrikeWrapper
        chart_attrs={props.chart_attrs}
        instrument={props.instrument}
        idx={props.idx}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "VolatalitesAgainstStrikeV2") {
    return (
      <VolatalitesAgainstStrikeV2
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "UnderlyingAggregateOpenInterest") {
    return (
      <UnderlyingAggregateOpenInterest
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "DailyUnderlyingAggregateOpenInterest") {
    return (
      <DailyUnderlyingAggregateOpenInterest
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "DailyUnderlyingPCRatioOI") {
    return (
      <DailyUnderlyingPCRatioOI
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "DailyUnderlyingAggregateOpenInterestByExpiry") {
    return (
      <DailyUnderlyingAggregateOpenInterestByExpiry
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "DailyUnderlyingOpenInterestDelta") {
    return (
      <DailyUnderlyingOpenInterestDelta
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "UnderlyingVolumeAggregatedByTradeDate") {
    return (
      <UnderlyingVolumeAggregatedByTradeDate
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "UnderlyingVolumeAggregatedByInstrument") {
    return (
      <UnderlyingVolumeAggregatedByInstrument
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else if (template == "UnderlyingVolumeAggregatedByPutCallBuySell") {
    return (
      <UnderlyingVolumeAggregatedByPutCallBuySell
        baseCurrency={instrument.base}
        display_type={props.display_type}
        instrument={props.instrument}
        chart_attrs={props.chart_attrs}
        chart_additional_attributes={props.chart_additional_attributes}
      />
    );
  } else {
    return (
      <h1>
        No chart has yet been configured in the factory for id {props.template}
      </h1>
    );
  }
};
