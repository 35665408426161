import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../global";

export const allOptionsApi = createApi({
  reducerPath: "allOptionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.ssa.url + "/api/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState() && getState().auth && getState().auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      // headers.set('content-type', 'application/json')
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loadATMIV: builder.query({
      query: (currency) => ({
        url: `loadATMIV`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadOhlc: builder.query({
      query: (currency) => ({
        url: `loadOhlc`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadMultiSeriesOhlc: builder.query({
      query: (currency) => ({
        url: `loadMultiSeriesOhlc`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadOptionVolumeHeatMap: builder.query({
      query: (currency) => ({
        url: `loadHourlyAverageVolumeHeatMap`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadDailyAggregateOptionsTradeVolumes: builder.query({
      query: (currency) => ({
        url: `dailyAggregateOptionsTradeVolumes`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadSkewVsDeltaOptions: builder.query({
      query: (currency) => ({
        url: `loadSkewVsDeltaOptions`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadTradeData: builder.query({
      query: (currency) => ({
        url: `tradeData`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadVolatalitiesVsStrike: builder.query({
      query: (currency) => ({
        url: `loadVolatalitiesVsStrike`,
        method: "POST",
        body: {
          currency: currency,
          "content-type": "application/json",
        },
      }),
    }),
    loadDeribitUnderlyingIndices: builder.query({
      query: (underlying) => ({
        url: `getDeribitUnderlyingIndices`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadDeribitDataForUnderlyingIndex: builder.query({
      query: (underlying_index) => ({
        url: `getDeribitDataForUnderlyingIndx`,
        method: "POST",
        body: {
          underlyingIndx: underlying_index,
          "content-type": "application/json",
        },
      }),
    }),
    loadDeribitUnderlyingAggregateOpenInterest: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingAggregateOpenInterest`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadDailyUnderlyingAggregateOpenInterestByStrike: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingDailyAggregateOpenInterestByStrike`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadDailyUnderlyingPCRatioOpenInterest: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingDailyPCRatioOfOILast30days`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadDailyUnderlyingAggregateOpenInterestByExpiry: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingDailyAggregateOpenInterestByExpiry`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadDailyUnderlyingOpenInterestDelta: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingDailyOpenInterestDelta`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadUnderlyingVolumeAggregatedByTradeDate: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingVolumeAggregatedByTradeDate`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadUnderlyingVolumeAggregatedByInstrument: builder.query({
      query: (underlying) => ({
        url: `getUnderlyingVolumeAggregatedByInstrument`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    loadUnderlyingAggVolPutCallBuySell: builder.query({
      query: (underlying) => ({
        url: `getAggVolPutCallBuySell`,
        method: "POST",
        body: {
          underlying: underlying,
          "content-type": "application/json",
        },
      }),
    }),
    requestOptionPriceCalc: builder.query({
      query: (option_contract_query, option_calculation_type) => ({
        url: `requestOptionPriceCalculation`,
        method: "POST",
        body: {
          option_contract_query: option_contract_query,
          option_calculation_type: option_calculation_type,
          "content-type": "application/json",
        },
      }),
    }),
    requestSingleOptionPriceCalc: builder.query({
      query: (
        excercise_type,
        option_type,
        strike,
        expiry,
        buy_sell,
        notional,
        asset
      ) => ({
        url: `requestSingleOptionPriceCalculation`,
        method: "POST",
        body: {
          exercise_type: excercise_type,
          option_type: option_type,
          strike: strike,
          expiry_date: expiry,
          buy_sell: buy_sell,
          notional: notional,
          asset: asset,
          "content-type": "application/json",
        },
      }),
    }),
    getAllCurrentQuantunaOptions: builder.query({
      query: (underlying, option_calculation_type) => ({
        url: `getAllCurrentQuantunaOptions`,
        method: "POST",
        body: {
          underlying: underlying,
          option_calculation_type: option_calculation_type,
          "content-type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoadATMIVQuery,
  useLoadOhlcQuery,
  useLoadMultiSeriesOhlcQuery,
  useLoadVolatalitiesVsStrikeQuery,
  useLoadOptionVolumeHeatMapQuery,
  useLoadDailyAggregateOptionsTradeVolumesQuery,
  useLoadSkewVsDeltaOptionsQuery,
  useLoadTradeDataQuery,
  useLoadDeribitUnderlyingIndicesQuery,
  useLoadDeribitDataForUnderlyingIndexQuery,
  useLoadDeribitUnderlyingAggregateOpenInterestQuery,
  useLoadDailyUnderlyingAggregateOpenInterestByStrikeQuery,
  useLoadDailyUnderlyingPCRatioOpenInterestQuery,
  useLoadDailyUnderlyingAggregateOpenInterestByExpiryQuery,
  useLoadDailyUnderlyingOpenInterestDeltaQuery,
  useLoadUnderlyingVolumeAggregatedByTradeDateQuery,
  useLoadUnderlyingVolumeAggregatedByInstrumentQuery,
  useLoadUnderlyingAggVolPutCallBuySellQuery,
  useRequestOptionPriceCalcQuery,
  useRequestSingleOptionPriceCalcQuery,
  useGetAllCurrentQuantunaOptionsQuery,
} = allOptionsApi;
