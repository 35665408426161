import React, { useState } from "react";
import { useGetLandingPageDashboardQuery } from "../../../../api/SettingsApi";
import {
  SimpleChartConstruction,
  WrappedChartConstruction,
} from "../../../dashboard/components/AssetCardForCoreDashboard";
import { QuantunaChartCard } from "../../../dashboard/components/basic/QuantunaChartCard";

// one template to load all the option dasbhboards based on the name passed from the inner template
const QuantunaOptionsDashboardLoader = (props) => {
  const [dashboardName, setDashboardName] = useState(props.dashboardName);

  // get all the charts for the dashboard
  const dashboardCharts = useGetLandingPageDashboardQuery(dashboardName);
  console.log("Read dashboard details from server - ", dashboardCharts);

  // lets generate the dasboard now
  const allChartData =
    dashboardCharts && dashboardCharts.isSuccess && dashboardCharts.data
      ? dashboardCharts.data
      : undefined;

  console.info("All charts data for dashboard", dashboardName, allChartData);

  var card_height = 400; // we gonna convert to fr and then we can mark sub-sections on the dashboard and fix the heights

  // iv to select for the underlying indices to construct the charts
  const selectedIV = props.iv;

  // For the option instruents, get this value from props later, pass the selectedIV as part of the instrument, and later use this to request the iv from service
  const instrumentName = props.coin;
  const instrument = {
    instrumentName: instrumentName,
    instrument_base: instrumentName,
    base: instrumentName,
    iv: selectedIV,
    strikeOrDelta: props.strikeOrDelta,
    category: "option",
  };

  const instrument_str = encodeURI(JSON.stringify(instrument));
  console.info("additional parameters are - ", instrument_str);

  const category = "option"; // we gonna take it from the charts coz these are only option-dashboards

  // pass along everything that comes in the chart_additional_arrtibures to the charts
  const chart_additional_attributes = {
    card_height: props.card_height,
    card_width: props.card_width,
  };

  return (
    <>
      {/* Lets simply spit all the details here and continue for now */}
      {allChartData &&
        allChartData.map((d, idx) => {
          // d["category"] = d.chart_category;
          // d["title"] = d.chart_description_1;
          // d["template"] = d.chart_template;

          if (!d.is_wrapper) {
            return (
              <div key={d.dashboard_chart_map_id} className="col-1-of-3">
                <QuantunaChartCard>
                  <SimpleChartConstruction
                    idx={idx}
                    instrument={instrument}
                    chart_attrs={d}
                    chart_additional_attributes={chart_additional_attributes}
                    card_height={card_height}
                  />
                </QuantunaChartCard>
              </div>
            );
          } else if (d.is_wrapper) {
            // let the wrapper-take care of generating the card-instances
            console.info("Processing the chart wrapper here", d);
            return (
              <WrappedChartConstruction
                chart_attrs={d}
                instrument={instrument}
                idx={idx}
                chart_additional_attributes={chart_additional_attributes}
              />
            );
          }
        })}
    </>
  );
};

export const QuantunaOptionsNewLandingInner_MarkAnalytics = (props) => {
  return (
    <>
      <QuantunaOptionsDashboardLoader
        dashboardName="QuantunaDeribitMarkAnalytics_DashBoard"
        containerClass="quantuna-landing-deribit-options-container"
        card_height={"80vh"}
        card_width={"80vh"}
        coin={props.selectedCoin}
      />
    </>
  );
};

export const QuantunaOptionsNewLandingInner_VoltAnalytics = (props) => {
  return (
    <>
      {/* Quantuna Options Landing Body Here */}
      {/* temptalize this part to load a template specific to the parameters selected => exchange + query params + tabs */}
      {/* The options dashboard will be made up of couple of different dashboards as the nature demands it */}
      {/* This section loads smaller charts */}

      {props.strikeOrDelta != "volsurface" && (
        <>
          <QuantunaOptionsDashboardLoader
            dashboardName="QuantunaDeribitStrikes_DashBoard"
            containerClass="quantuna-landing-deribit-options-container"
            coin={props.selectedCoin}
            iv={props.selectedIV}
            strikeOrDelta={props.strikeOrDelta}
          />
        </>
      )}

      {/* This section loads vol surface chart and shows it on the tab switch */}
      {props.strikeOrDelta == "volsurface" && (
        <QuantunaOptionsDashboardLoader
          dashboardName="QuantunaDeribit_DashBoard_Sec3"
          containerClass="quantuna-landing-deribit-options-container-3"
          card_height={"80vh"}
          card_width={"80vh"}
          coin={props.selectedCoin}
        />
      )}
    </>
  );
};

export const QuantunaOptionsNewLandingInner = (props) => {
  const coinData = props.coinData;

  // by default select BTC to build charts
  const [selectedCoin, setSelectedCoin] = useState("BTC");

  // setting the right side menu selection for the vol or market charts
  const [selectedVolOrMarket, setSelectedVolOrMarket] =
    useState("volatilityoptions");

  // The IV values are markIV or bidAskIV
  const [selectedIV, setSelectedIV] = useState("markIV");

  // Whether Strike or Delta selected
  const [strikeOrDelta, setStrikeOrDelta] = useState("strike");

  // set the selected coin on clicking the coin
  const onCoinClickHandler = (event) => {
    console.log("Clicked the button - " + event.target.value);
    setSelectedCoin(event.target.value);
  };

  const onVolMarketClickHandler = (event) => {
    console.log(
      "Clicked vol-market button for changing the charts, flipping now - " +
        event.target.value
    );
    setSelectedVolOrMarket(event.target.value);
  };

  const onIVClickHandler = (event) => {
    console.log("Setting the selected IV value to - " + event.target.value);
    setSelectedIV(event.target.value);
  };

  const onStrikeDeltaChangeHandler = (event) => {
    console.info("Clicked the strike/delta/vol button", event.target.value);
    setStrikeOrDelta(event.target.value);
  };

  const onOptionExpiryRangeChange = (value) => {
    console.log("Value changed to", value);
  };

  // lets define the option header highligh option here
  const menuBorderBottomHighlight =
    selectedVolOrMarket == "volatilityoptions"
      ? " menu-bottom-border-highlight-volt-anal "
      : selectedVolOrMarket == "marketoptions"
      ? " menu-bottom-border-highlight-mark-anal "
      : "";

  // collect all the data and fn here
  const dataAndFn = {
    ...props,
    selectedCoin: selectedCoin,
    selectedVolOrMarket: selectedVolOrMarket,
    selectedIV: selectedIV,
    strikeOrDelta: strikeOrDelta,
    onCoinClickHandler: onCoinClickHandler,
    onVolMarketClickHandler: onVolMarketClickHandler,
    onIVClickHandler: onIVClickHandler,
    onStrikeDeltaChangeHandler: onStrikeDeltaChangeHandler,
    onOptionExpiryRangeChange: onOptionExpiryRangeChange,
    menuBorderBottomHighlight: menuBorderBottomHighlight,
  };

  return (
    <main>
      <section className="section-option-menu">
        {/* menu items to come here */}
        <div className="section-option-menu__wrapper">
          <div className="section-option-menu__wrapper__left">
            {/* the left side menu items will show up here */}
            {/* Strikes, Delta, Atm Ratio to come here */}
            <button
              className={
                "section-option-menu__btn section-option-menu__strike" +
                (strikeOrDelta == "strike"
                  ? " section-option-menu__btn__active "
                  : "")
              }
              value={"strike"}
              onClick={onStrikeDeltaChangeHandler}
            >
              Strikes
            </button>
            <button
              className={
                "section-option-menu__btn section-option-menu__delta" +
                (strikeOrDelta == "delta"
                  ? " section-option-menu__btn__active "
                  : "")
              }
              value={"delta"}
              onClick={onStrikeDeltaChangeHandler}
            >
              Delta
            </button>
            <button
              className={
                "section-option-menu__btn section-option-menu__volsrfc" +
                (strikeOrDelta == "volsurface"
                  ? " section-option-menu__btn__active "
                  : "")
              }
              value={"volsurface"}
              onClick={onStrikeDeltaChangeHandler}
            >
              Vol Surface
            </button>
          </div>
          <div className="section-option-menu__wrapper__center">
            {strikeOrDelta == "strike" && (
              <div className="quantuna-split-btn-container">
                <button
                  key={"markIV"}
                  value={"markIV"}
                  className={
                    "section-option-menu__btn-split section-option-menu__btn-split__left " +
                    (selectedIV == "markIV"
                      ? " section-option-menu__btn-split__active "
                      : "")
                  }
                  onClick={onIVClickHandler}
                >
                  Mark IV
                </button>
                <button
                  key={"bidAskIV"}
                  value={"bidAskIV"}
                  className={
                    "section-option-menu__btn-split section-option-menu__btn-split__right " +
                    (selectedIV == "bidAskIV"
                      ? " section-option-menu__btn-split__active "
                      : "")
                  }
                  onClick={onIVClickHandler}
                >
                  Bid/Ask
                </button>
              </div>
            )}
          </div>
          <div className="section-option-menu__wrapper__right">
            <div
              key={"optioncoins" + "1div"}
              className="section-option-menu__coinoptions"
            >
              {coinData &&
                coinData.map((d, idx) => (
                  <div
                    className="section-option-menu__coingroup"
                    key={idx + d.coinSymbol + "optioncoins" + "div1"}
                  >
                    <input
                      type="radio"
                      className="section-option-menu__coingroup__input"
                      value={d.coinSymbol.toUpperCase()}
                      id={idx + d.coinSymbol + "optioncoins"}
                      name="coin"
                      checked={
                        selectedCoin == d.coinSymbol.toUpperCase()
                          ? "checked"
                          : ""
                      }
                      onChange={onCoinClickHandler}
                    />
                    <label
                      htmlFor={idx + d.coinSymbol + "optioncoins"}
                      className="section-option-menu__coingroup__label"
                    >
                      <img
                        className="section-option-menu__coingroup__label__img"
                        src={`data:image/png;base64,${d.coinImage}`}
                      />
                    </label>
                  </div>
                ))}
            </div>

            {/* Whether the market analysis or volatility analysis to show */}
            <div
              key={"optioncoins" + "2div"}
              className="section-option-menu__coinoptions"
            >
              <div
                className="section-option-menu__coingroup"
                key={"optioncoins" + "div2"}
              >
                <input
                  type="radio"
                  className="section-option-menu__coingroup__input"
                  name="volOrMarket"
                  id={"volatilityoptions"}
                  key={"volatilityoptions"}
                  value={"volatilityoptions"}
                  checked={
                    selectedVolOrMarket == "volatilityoptions" ? "checked" : ""
                  }
                  onChange={onVolMarketClickHandler}
                />
                <label
                  htmlFor="volatilityoptions"
                  className="section-option-menu__coingroup__label"
                >
                  <span className=""></span>Volatility
                </label>

                <input
                  type="radio"
                  className="section-option-menu__coingroup__input"
                  name="volOrMarket"
                  id={"marketoptions"}
                  key={"marketoptions"}
                  value={"marketoptions"}
                  checked={
                    selectedVolOrMarket == "marketoptions" ? "checked" : ""
                  }
                  onChange={onVolMarketClickHandler}
                />
                <label
                  htmlFor="marketoptions"
                  className="section-option-menu__coingroup__label"
                >
                  <span className=""></span>Market
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-options">
        <div className="section-options__options-container">
          {/* Quantuna Landing Top Menu Items Here Spot, Dashboards, Indices */}
          {/* making buttons for various borad level products here */}
          {/* START: This is volatility analytics section */}
          {selectedVolOrMarket == "volatilityoptions" && (
            <QuantunaOptionsNewLandingInner_VoltAnalytics {...dataAndFn} />
          )}

          {/* END of the vol surface section */}
          {/* START: This is market analytics section */}
          {selectedVolOrMarket == "marketoptions" && (
            <QuantunaOptionsNewLandingInner_MarkAnalytics {...dataAndFn} />
          )}

          {/* END of the market analytics section */}
        </div>
      </section>
    </main>
  );
};
