import { useLocation } from "react-router";

// java rest server end points
export const servicePathProd = "https://api.quantuna.com";
export const servicePathDev = "http://localhost:8060";

// python rest server end points
export const servicePathProdPy = "http://34.235.126.209:80";
export const servicePathDevPy = "http://localhost:8026";

export const servicePathAnalyticsDevPy = "http://localhost:8022";
export const servicePathAnalyticsProdPy = "https://options-api.quantuna.com";

let servicePathTemp = servicePathProd;
let isProdServerTemp = true;

let servicePathTempPy = servicePathProdPy;
let isProdServerTempPy = true;

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === ""
) {
  console.log("It's a local server!");
  servicePathTemp = servicePathDev;
  servicePathTempPy = servicePathDevPy;
} else {
  console.log("It's a prod server!");
  isProdServerTemp = true;
  isProdServerTempPy = true;
}

console.log("Loading the data from " + servicePathTemp);

export const servicePath = servicePathProd; //servicePathTemp; // servicePathTemp;
export const isProdServer = isProdServerTemp;
export const servicePathPy = servicePathProdPy; // servicePathProdPy; //servicePathTemp; // servicePathTemp;
export const isProdServerPy = isProdServerTempPy;
export const servicePathAnalyticsPy = servicePathAnalyticsProdPy;

// export const servicePath = servicePathDev; //servicePathTemp; // servicePathTemp;
// export const isProdServer = false; // isProdServerTemp;
// export const servicePathPy = servicePathDevPy; // servicePathProdPy; //servicePathTemp; // servicePathTemp;
// export const servicePathAnalyticsPy = servicePathAnalyticsDevPy;
// export const isProdServerPy = false;

export const useOriginLocation = () => {
  return window.location.origin;
};

export const usePathname = () => {
  const location = useLocation();
  console.info(location);
  return location.pathname;
};

// all api end points
export const api = {
  ssa: {
    url: servicePath,
    pyurl: servicePathPy,
    pyAnalurl: servicePathAnalyticsPy,
    wsUrl: servicePath + "/analyticsData",
    loadOrderBook: "api/loadOrderBook",
    priceSingleOption: "api/requestSingleOptionPriceCalculation",
    connectWithApiKey: "api/connectWithApiKey",
    loadVolatalitiesStrikes: "loadVolatalitiesStrikes",
    loadATMIV: "loadATMIV",
    loadOptionVolumeHeatMap: "loadHourlyAverageVolumeHeatMap",
    loadSkewVsDeltaOptions: "loadSkewVsDeltaOptions",
    loadVolatalitiesVsStrike: "loadVolatalitiesVsStrike",
    loadSpotHourlyReturnsHeatMap: "loadSpotHourlyReturnsHeatMap",
    loadSpotVolumeHeatMap: "loadSpotVolumeHeatMap",
    loadTopTradedSpotInstruments: "loadTopTradedSpotInstruments",
    loadOhlc: "loadOhlc",
    tradeData: "tradeData",
    dailyAggregateOptionsTradeVolumes: "dailyAggregateOptionsTradeVolumes",
    fetchDailyPortfolioValue: "getDailyAssetPrices",
    spotDailyClosingPrices: "spotDailyClosingPrices",
    loadVolSurface: "loadVolSurface",
    requestRawData: "requestRawData",
    registerUser: "registerUser",
    login: "login",
    resetPassword: "resetPassword",
    saveNewPassword: "saveNewPassword",
    loadStatsStd: "loadStatsStd",
    loadStatsCorrelationMatrix: "loadStatsCorrelationMatrix",
    loadStatsCorrelationSeries: "loadStatsCorrelationSeries",
    signin: "api/ssa/signin",
    requestFreeAccessToken: "api/product_offerings/request_free_access_token",
    getDataArchiveLogsSize: "api/getDataArchiveLogsSize",
    getDataArchiveLogs: "api/getDataArchiveLogs",
    sendDataRequestEmail: "sendDataRequestEmail",
    sendGeneralInquiryEmail: "sendGeneralInquiryEmail",
    priceSingleOptionAnalyticsService:
      "opcl/price/{asset}/{exercise_type}/{option_type}/{strike}/{expiry_date}",
    getAllCurrentQuantunaOptions: "api/getAllCurrentQuantunaOptions",
    getAllStraddleAndStrangleData: "api/getAllStraddleAndStrangleData",
    getAllButterflyData: "api/getAllButterflyData",
    getAllIronCondors: "api/getAllIronCondors",
  },
  others: {},
};

export const code = {
  //Authentication
  READY: "ready",
  INVALID_LOGIN: 101,
  AUTH_HEADER_REQUIRED: 105,

  //General
  SUCCESS: 200,
  FAIL: 0,
};
