import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../global";

export const allProductOfferingsApi = createApi({
  reducerPath: "allProductOfferingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.ssa.url + "/api/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState() && getState().auth && getState().auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      // headers.set('content-type', 'application/json')
      return headers;
    },
  }),
  endpoints: (builder) => ({
    requestFreeAccessToken: builder.query({
      query: (client_free_access_token_order_val) => ({
        url: `requestFreeAccessToken`,
        method: "POST",
        body: {
          client_free_access_token_order: client_free_access_token_order_val,
          "content-type": "application/json",
        },
      }),
    }),
    getDataArchiveLogsSize: builder.query({
      query: (data_type) => ({
        url: `getDataArchiveLogsSize`,
        method: "POST",
        body: {
          data_type: data_type,
          "content-type": "application/json",
        },
      }),
    }),
    getDataArchiveLogs: builder.query({
      query: (payload) => ({
        url: `getDataArchiveLogs`,
        method: "POST",
        body: {
          data_type: payload.data_type,
          page_size: payload.page_size,
          page_num: payload.page_num,
          "content-type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  requestFreeAccessToken,
  useGetDataArchiveLogsSizeQuery,
  useGetDataArchiveLogsQuery,
} = allProductOfferingsApi;
