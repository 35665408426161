import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";
import "./css/style.comp.css";
// import "./index.css";
// import "./assets/scss/style.scss";
import AppRoutes from "./App";
import { RouterProvider } from "react-router";
import { store } from "./redux/Store";
import { Provider } from "react-redux";

// NEW CODE
const router = createBrowserRouter(AppRoutes);
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);
