import React, { useState } from "react";
import {
  useLoadDeribitDataForUnderlyingIndexQuery,
  useLoadDeribitUnderlyingIndicesQuery,
  useLoadVolatalitiesVsStrikeQuery,
} from "../../../../api/OptionsApi";
import { useLoadChartListForIdQuery } from "../../../../api/SettingsApi";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import { Card, CardBody } from "reactstrap";
import { SimpleChartConstruction } from "../../../dashboard/components/AssetCardForCoreDashboard";
import OptionsOrdersTable from "../../tables/OptionsOrdersTable";
import transparent from "../../../../assets/images/quantuna-name-transparent-light.png";
import { QuantunaChartTitle } from "../../../dashboard/components/basic/BasicChartComponents";
import { QuantunaChartCard } from "../../../dashboard/components/basic/QuantunaChartCard";
// import transparent from "../../../assets/images/quantuna-name-transparent-light.png";

// The following chart component should be invoked for all the active expiries and hence generate all the charts
export const VolatalitesAgainstStrike = (props) => {
  // Volatilities can be mark-iv or bid-ask, this chart will be created with an input for the ivType => the server should receive the additional ivType flag and send the data accordingly
  const { data, error, isLoading } = useLoadVolatalitiesVsStrikeQuery(
    props.baseCurrency
  );

  console.info("Receiving data from server for VolatalitesAgainstStrike", data);

  HC_exporting(Highcharts);

  const getOption = () => {
    return {
      title: {
        text: "IV vs Strike - " + props.baseCurrency,
      },

      subtitle: {
        text: "Source: Quantuna Analytics",
      },

      yAxis: {
        title: {
          text: "IV",
        },
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      chart: {
        zoomType: "x",
      },
      xAxis: {
        title: {
          text: "Strike",
        },
      },
      series: [
        {
          name: "Call IV",
          data: data && data.callIVData,
        },
        {
          name: "Put IV",
          data: data && data.putIVData,
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  } else {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          immutable={true}
          options={getOption()}
        />
      </div>
    );
  }
};

const constructSeriesData = (
  strike_or_delta,
  selected_iv,
  data,
  seriesData,
  seriesLegend,
  seriesColor
) => {
  // based on how many charts are selected, we will need to add all those series here to the charts

  if (strike_or_delta == "strike" && selected_iv == "markIV") {
    seriesData.push({
      name: "Call IV" + `${seriesLegend}`,
      data: data && data.markIv && data.markIv.callIVData,
      color: `${seriesColor}`,
    });
    seriesData.push({
      name: "Put IV" + `${seriesLegend}`,
      data: data && data.markIv && data.markIv.putIVData,
      color: `${seriesColor}`,
    });
  } else if (strike_or_delta == "strike" && selected_iv == "bidAskIV") {
    seriesData.push({
      name: "Bid Call IV" + `${seriesLegend}`,
      data: data && data.bidIv && data.bidIv.callIVData,
      color: `${seriesColor}`,
    });
    seriesData.push({
      name: "Bid Put IV" + `${seriesLegend}`,
      data: data && data.bidIv && data.bidIv.putIVData,
      color: `${seriesColor}`,
    });
    seriesData.push({
      name: "Ask Call IV" + `${seriesLegend}`,
      data: data && data.askIv && data.askIv.callIVData,
      color: `${seriesColor}`,
    });
    seriesData.push({
      name: "Ask Put IV" + `${seriesLegend}`,
      data: data && data.askIv && data.askIv.putIVData,
      color: `${seriesColor}`,
    });
  } else if (strike_or_delta == "delta") {
    seriesData.push({
      name: "Delta " + `${seriesLegend}`,
      data: data && data.deltaIv && data.deltaIv.deltaData,
      color: `${seriesColor}`,
    });
  }
};

export const VolatalitesAgainstStrikeV2_Intern = (props) => {
  // Volatilities can be mark-iv or bid-ask, this chart will be created with an input for the ivType => the server should receive the additional ivType flag and send the data accordingly
  // This is a copy of the other namesake chart and instead utilizes undelyingIndex to get the data instead of the base currency
  const underlying_index = props.instrument.underlyingIndex;
  const selected_iv = props.instrument.iv;
  const strike_or_delta = props.instrument.strikeOrDelta;
  const display_type = props.display_type;
  const instrument = props.instrument;
  const container_width = props.instrument.container_width;
  const chart_attrs = props.chart_attrs;

  console.info(
    "VolatalitesAgainstStrikeV2 data for chart building display_type: ",
    display_type,
    ", instrument: ",
    instrument,
    ", chart-attrs: ",
    chart_attrs
  );

  let xAxisCaption = "Strike";
  if (strike_or_delta == "delta") {
    xAxisCaption = "Delta";
  }

  const deribitDataForUnderlyingIndexQ =
    useLoadDeribitDataForUnderlyingIndexQuery(underlying_index);

  const data =
    deribitDataForUnderlyingIndexQ &&
    deribitDataForUnderlyingIndexQ.isSuccess &&
    deribitDataForUnderlyingIndexQ.data
      ? deribitDataForUnderlyingIndexQ.data
      : undefined;

  const seriesData = [];
  data &&
    constructSeriesData(
      strike_or_delta,
      selected_iv,
      data,
      seriesData,
      "",
      "#777"
    );

  props.comparative7d &&
    data &&
    data.comparative7Days &&
    constructSeriesData(
      strike_or_delta,
      selected_iv,
      data.comparative7Days,
      seriesData,
      "(7d)",
      "#f35050"
    );

  props.comparative30d &&
    data &&
    data.comparative30Days &&
    constructSeriesData(
      strike_or_delta,
      selected_iv,
      data.comparative30Days,
      seriesData,
      "(30d)",
      "#50f450"
    );

  props.comparative90d &&
    data &&
    data.comparative90ays &&
    constructSeriesData(
      strike_or_delta,
      selected_iv,
      data.comparative90ays,
      seriesData,
      "(90d)",
      "#64aceb"
    );

  props.comparative180d &&
    data &&
    data.comparative180ays &&
    constructSeriesData(
      strike_or_delta,
      selected_iv,
      data.comparative180ays,
      seriesData,
      "(180d)",
      "#f4a0e5"
    );

  console.info(
    "Receiving data from server for VolatalitesAgainstStrikeV2",
    deribitDataForUnderlyingIndexQ
  );

  HC_exporting(Highcharts);

  const getOption = () => {
    return {
      title: {
        text: underlying_index,
      },

      subtitle: {
        text: `${chart_attrs.description_1}`,
      },

      credits: {
        enabled: true,
        href: "https://www.quantuna.com",
        text: "www.quantuna.com",
      },

      yAxis: {
        title: {
          text: "IV",
        },
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      chart: {
        zoomType: "x",
        events: {
          render: function () {
            const chart = this,
              bgBBox = chart.plotBackground.getBBox();

            if (!chart.bgImage) {
              chart.bgImage = chart.renderer
                .image(transparent, 0, 0, 300, 100)
                .add();
            }

            chart.bgImage.attr({
              x: "calc(50% - 150px)",
              y: "calc(50% - 75px)",
            });
          },
        },
      },
      xAxis: {
        title: {
          text: xAxisCaption,
        },
        type: "string",
      },
      series: seriesData,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };
  };

  if (display_type == "chart") {
    if (!data) {
      return <div>Loading ...</div>;
    } else {
      return (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            immutable={true}
            options={getOption()}
          />
        </div>
      );
    }
  } else if (display_type == "table") {
    return (
      <div>
        <OptionsOrdersTable
          chart_data={data.data}
          container_width={container_width}
        />
      </div>
    );
  }
};

export const VolatalitesAgainstStrikeV2 = (props) => {
  // we can add the chart specific handlers for the title bar here
  const [comparative7d, setComparative7d] = useState(false);
  const [comparative30d, setComparative30d] = useState(false);
  const [comparative90d, setComparative90d] = useState(false);
  const [comparative180d, setComparative180d] = useState(false);

  const click73090HandlerFn = (event) => {
    event.preventDefault();
    console.info(
      "Enabling the comparative chart now",
      event.currentTarget.value,
      "xxx",
      event.currentTarget.daysToExpiry
    );

    if (event.currentTarget.value == 7) {
      console.info(
        "Enabling the 7-day comparative chart now",
        event.currentTarget.value
      );
      setComparative7d(!comparative7d);
    }

    if (event.currentTarget.value == 30) {
      setComparative30d(!comparative30d);
    }

    if (event.currentTarget.value == 90) {
      setComparative90d(!comparative90d);
    }

    if (event.currentTarget.value == 180) {
      setComparative180d(!comparative180d);
    }
  };
  return (
    <>
      <QuantunaChartTitle {...props} click73090Handler={click73090HandlerFn} />
      <VolatalitesAgainstStrikeV2_Intern
        {...props}
        comparative7d={comparative7d}
        comparative30d={comparative30d}
        comparative90d={comparative90d}
        comparative180d={comparative180d}
      />
    </>
  );
};

// Wrapper charts will not be confined within their own cards but they will just act like the data-provider or functionality provider for the low level wrapped charts
// The following wrapped chart also will have additional 7, 30, 90, 180 days chart comparison handler passed down
export const VolatalitesAgainstStrikeWrapper = (props) => {
  // Query for all the conditions which will be used to generate instances of the wrapped_chart
  const wrappedChartId = props.chart_attrs.wrapped_chart_id;
  const instrumentBase = props.instrument.base;
  const parentIdx = props.idx * 100;
  const instrument = props.instrument;

  // load the chart definition for the wrapped chart id
  const wrappedChartData = useLoadChartListForIdQuery(wrappedChartId);
  const wrappedChart =
    wrappedChartData && wrappedChartData.isSuccess && wrappedChartData.data
      ? wrappedChartData.data
      : undefined;

  // we now have the wrapped chart and instrument for which to generate the instances of the wrap-chart
  // let us get the repititive element => underlying indices for today to generate the charts => getDeribitUnderlyingIndices
  const underlyingIndicesData =
    useLoadDeribitUnderlyingIndicesQuery(instrumentBase);
  const underlyingIndices =
    underlyingIndicesData &&
    underlyingIndicesData.isSuccess &&
    underlyingIndicesData.data
      ? underlyingIndicesData.data
      : undefined;

  // now we build charts using the underlyingIndices

  console.info(
    "Loading wrappedChart for ",
    wrappedChartId,
    wrappedChart,
    underlyingIndices
  );

  // handle comparitive chart events
  const handle73090180Copmarative = (event) => {
    console.info("Enabling/disabling selected comparative chart");
  };

  var card_height = 400; // we gonna convert to fr and then we can mark sub-sections on the dashboard and fix the heights
  return (
    <>
      {wrappedChart &&
        underlyingIndices &&
        underlyingIndices.map((uidx, idx) => {
          // extend instrument and underlying-index here => pass this instrumnt to the chart-factory so that we have enerything from one place
          const instrumentExtended = {
            ...instrument,
            ...uidx,
          };
          return (
            <div key={parentIdx + idx + "VolatalitesAgainstStrikeWrapper"}>
              <QuantunaChartCard>
                <SimpleChartConstruction
                  idx={idx + parentIdx}
                  card_height={card_height}
                  instrument={instrumentExtended}
                  chart_attrs={wrappedChart}
                />
              </QuantunaChartCard>
            </div>
          );
        })}
    </>
  );
};

// There are many custom constructs in the VolatalitesAgainstStrikeV2 chart
// Instead of depending on the basic-chart-components, building the whole chart here
// Keep in mind that once the chart construction becomes complex, we should do the same for other charts too
// BasicChartComponents is to only give us a speedy chart construction way
export const VolatalitesAgainstStrikeV2CustomConstruct = (props) => {
  return <>Under construction</>;
};
