import React from "react";
import { useRequestOptionPriceCalcQuery } from "../../../api/OptionsApi";

const OptionPricerResultDisplayRow = (props) => {
  // display each option data in a row so that it can be refreshed
  // asynchornousely for each record
  const option_contract_c = props.option_contract["C"];
  const option_contract_p = props.option_contract["P"];
  console.info(
    "Building the option row for display - ",
    props.option_contract,
    option_contract_c,
    option_contract_p
  );

  // contract_name, option_calculation_type, calculation_date, asset, option_type, strike, maturity, current_maturity,
  // expiry_date, price, implied_vol, delta, gamma, vega, theta, rho, creation_date
  return (
    <>
      <div className="pricer-result-container__row">
        {/* two side of the strike will be displayed here - Call on the left and put on the right */}
        <div className="pricer-result-container__row__cell">C</div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["maturity"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] && option_contract_c["data"]["price"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] &&
            option_contract_c["data"]["implied_vol"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] && option_contract_c["data"]["delta"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] && option_contract_c["data"]["gamma"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] && option_contract_c["data"]["vega"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] && option_contract_c["data"]["theta"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["data"] && option_contract_c["data"]["rho"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["strike"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] && option_contract_p["data"]["rho"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] && option_contract_p["data"]["theta"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] && option_contract_p["data"]["vega"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] && option_contract_p["data"]["gamma"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] && option_contract_p["data"]["delta"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] &&
            option_contract_p["data"]["implied_vol"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_p["data"] && option_contract_p["data"]["price"]}
        </div>
        <div className="pricer-result-container__row__cell">
          {option_contract_c["expiry_date"]}
        </div>
        <div className="pricer-result-container__row__cell"> P </div>
      </div>
    </>
  );
};

export const OptionPricerResultDisplay = (props) => {
  // submit the calcualte request with the backend for the given query
  const option_query = props.option_query;

  // using the option query, get all the option contract for subscription
  console.info("Requesting option contracts for query - " + option_query);
  const allOptionContractsQ = useRequestOptionPriceCalcQuery(
    option_query,
    "EUR"
  );

  const allOptionContracts =
    allOptionContractsQ &&
    allOptionContractsQ.isSuccess &&
    allOptionContractsQ.data
      ? allOptionContractsQ.data
      : undefined;

  console.info(allOptionContracts);

  // generate the grid to display the values as soon as those become available
  // subscribe to the backend to get the values to display
  return (
    <div className="pricer-result-container">
      {/* results here for {props.option_query} */}
      {/* lets make result rows here which will all be display grid too */}
      <div className="pricer-result-container__row">
        {/* all the result records here */}
        {/* European Options */}
        {/* option_type 	strike 	days_to_maturity 	price 	implied_vol 	delta 	gamma 	vega 	theta 	rho */}
        <div className="pricer-result-container__row__cell">option_type</div>
        <div className="pricer-result-container__row__cell">
          days_to_maturity
        </div>
        <div className="pricer-result-container__row__cell">price</div>
        <div className="pricer-result-container__row__cell">implied_vol</div>
        <div className="pricer-result-container__row__cell">delta</div>
        <div className="pricer-result-container__row__cell">gamma</div>
        <div className="pricer-result-container__row__cell">vega</div>
        <div className="pricer-result-container__row__cell">theta</div>
        <div className="pricer-result-container__row__cell">rho</div>

        <div className="pricer-result-container__row__cell pricer-result-container__row__cell__strike">
          strike
        </div>

        <div className="pricer-result-container__row__cell">rho</div>
        <div className="pricer-result-container__row__cell">theta</div>
        <div className="pricer-result-container__row__cell">vega</div>
        <div className="pricer-result-container__row__cell">gamma</div>
        <div className="pricer-result-container__row__cell">delta</div>
        <div className="pricer-result-container__row__cell">implied_vol</div>
        <div className="pricer-result-container__row__cell">price</div>
        <div className="pricer-result-container__row__cell">expiry_date</div>
        <div className="pricer-result-container__row__cell">option_type</div>
      </div>
      {allOptionContracts &&
        Object.entries(allOptionContracts).map(
          ([option_contract_k, option_contract_v]) => (
            <OptionPricerResultDisplayRow option_contract={option_contract_v} />
          )
        )}
    </div>
  );
};
