import React, { Component } from "react";
import Plot from "react-plotly.js";
import { useLoadVolSurfaceQuery } from "../../../api/VolSurfaceApi";

export const VolSurface = (props) => {
  const { data, error, isLoading } = useLoadVolSurfaceQuery(props.baseCurrency);

  return (
    <Plot
      data={[
        {
          type: "surface",
          x: data && data.columns,
          y: data && data.index,
          z: data && data.data,
          colorscale: "Electric",
          showscale: true,
        },
      ]}
      config={{
        responsive: true,
      }}
      layout={{
        autosize: false,
        margin: {
          l: 50,
          r: 50,
          b: 80,
          t: 90,
          pad: 4,
        },
        title: props.baseCurrency + " Vol Surface",
        annotations: [
          {
            text: "",
            font: {
              size: 14,
              color: "#444444",
            },
            showarrow: false,
            align: "center",
            x: 0.5,
            y: 1.1,
            xref: "paper",
            yref: "paper",
          },
        ],
        scene: {
          xaxis: {
            title: "Time to expiration",
            titlefont: {
              family: "Courier New, monospace",
              size: 12,
              color: "#444444",
            },
          },
          yaxis: {
            title: "Strikes",
            titlefont: {
              family: "Courier New, monospace",
              size: 12,
              color: "#444444",
            },
          },
          zaxis: {
            title: "Vol Surface",
            titlefont: {
              family: "Courier New, monospace",
              size: 12,
              color: "#444444",
            },
          },
        },
      }}
    />
  );
};
