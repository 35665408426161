import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../global";

export const allSettingsApi = createApi({
  reducerPath: "allSettingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.ssa.url + "/api/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState() && getState().auth && getState().auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      // headers.set('content-type', 'application/json')
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loadChartListForCategory: builder.query({
      query: (category) => ({
        url: `getChartListForCat`,
        method: "POST",
        body: {
          category: category,
          "content-type": "application/json",
        }, // This is the same as passing 'text'
      }),
    }),
    loadChartListForId: builder.query({
      query: (chartId) => ({
        url: `getChartListForId`,
        method: "POST",
        body: {
          chartId: chartId,
          "content-type": "application/json",
        }, // This is the same as passing 'text'
      }),
    }),
    loadMappedChartList: builder.query({
      query: () => ({
        url: `getMappedChartList`,
        method: "POST",
        body: {
          "content-type": "application/json",
        }, // This is the same as passing 'text'
      }),
    }),
    getLandingPageDashboard: builder.query({
      query: (dashboardId) => ({
        url: `getLandingPageDashboard`,
        method: "POST",
        body: {
          dashboardId: dashboardId,
          "content-type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useLoadChartListForCategoryQuery,
  useLoadMappedChartListQuery,
  useGetLandingPageDashboardQuery,
  useLoadChartListForIdQuery,
} = allSettingsApi;
